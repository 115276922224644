import { Alert, Box, LinearProgress, Snackbar, SnackbarProps } from '@mui/material';
import { LoadingProgress } from 'hsbshareviewer/src/model.api/modelviewer/LoadingProgress';
import { useCallback, useEffect, useState } from 'react';
import styles from './LoadingBar.module.scss';

export interface ViewerLoadingBarProps extends SnackbarProps {
    progress: LoadingProgress;
    message: string;
}

export default function ViewerLoadingBar(props: ViewerLoadingBarProps) {
    let { progress, message: inMessage, ...snackbarProps } = props;
    const [currProgress, setProgress] = useState(0);
    const [message, setMessage] = useState(inMessage)

    //Updates the progress callback
    const updater = useCallback((value) => {
        setProgress(value * 100);
    }, [])

    // Add progress listeners
    useEffect(() => {
        if (!progress)
            return;
        progress.onProgress(updater, { interval: 2.5 })
        progress.onStatusChange((status: string) => {
            setMessage(status);
        })
        progress.onComplete(() => {
            setProgress(100);
            setMessage('Finished');
        })
        return () => {
            progress.removeAllListeners();
        }
    }, [progress, updater])

    return (
        <Snackbar
            {...snackbarProps}>
            <Box >
                <Alert classes={{ root: styles.alert }} severity="info" sx={{ width: '100%' }}>
                    {`${message} ${currProgress > 0 ? `: ${currProgress.toFixed(1)}% complete` : ""}`}
                </Alert>
                <LinearProgress className={styles.linearProgress} />
            </Box>
        </Snackbar>
    )
}
