import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import styles from '../IssueDialog.module.scss'

export interface IPropertyChange {
  propName?: string;
  propOrgValue?: string;
  propNewValue?: string;
  propKey?: string;
}

const DisplayChange = (propertyChange: IPropertyChange, index: number) => {
  return propertyChange?.propName &&
    <Grid key={`propChange${propertyChange.propName}${index}`} className={styles['property-change-container']}>
      <span>{propertyChange.propName}:</span>
      <span>{propertyChange.propOrgValue?.toLowerCase() ?? (propertyChange.propKey === 'issueAssignedTo' ? 'Unassigned' : 'Unset')}</span>
      <ArrowRightAltIcon />
      <span>{propertyChange.propNewValue?.toLowerCase() ?? (propertyChange.propKey === 'issueAssignedTo' ? 'Unassigned' : 'Unset')}</span>
    </Grid>
}

export default function DisplayPropertyChange(props: React.PropsWithChildren<{ changes: IPropertyChange | IPropertyChange[] }>) {
  return (
    <Stack style={{ width: '100%', margin: "0" }} direction='column'>
      {
        Array.isArray(props.changes) ? props.changes?.map((propChange, index) => DisplayChange(propChange, index))
          : DisplayChange(props.changes, 0)
      }
    </Stack>
  )
}
