import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Alert, AlertColor, Box, Button, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, LinearProgress, Snackbar, TextField, Typography } from "@mui/material";
import DialogBox from "components/Shared/DialogBox";
import { FormInputFile } from "components/Shared/FormComponents/FormInputFile";
import { FormInputText } from "components/Shared/FormComponents/FormInputText";
import Tab from "components/Shared/Tab";
import SnackBarMessage, { ISnackBarData } from "components/Shared/snackBar/SnackBarMessage";
import useSession from "hooks/useSession";
import { ShareData, nodeLoaderService } from "hsbshareviewer";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import automationService from "services/automation";
import { IShareSession } from "types/IShareSession";
import { ModelState } from "types/enums";
import { shareModelDefinition } from "../ModelViewer";
import ModelItem from "./ModelItem";
import styles from "./ModelsPanel.module.scss";

export interface Revision {
  _id: string;
  documents: any;
  itemtype: string;
  modelId: string;
  modelName: string;
  projectId: string;
  revision: string;
  setName: string;
  status: ModelState;
  active: boolean;
  updatedAt: Date;
  createdAt: Date;
  createdBy: any;
  description?: string;
  node: any;
}

export interface Model {
  source: string;
  unit: string;
  createdAt: Date;
  updatedAt: Date;
  activeRevision: Revision;
  revisions: Revision[];
  createdBy: any;
  status: string;
}

interface UploadModelDialogData {
  file: File;
  name: string;
  version: string;
  active: boolean;
}

export function UploadModelDialog(props: { onUploadProgress; onAutomationServiceStarting } & DialogProps): JSX.Element {
  let { onSubmit, onUploadProgress, onAutomationServiceStarting, ...dialogProps } = props;
  const { handleSubmit, control, setValue, reset } = useForm<UploadModelDialogData>({
    mode: "onChange",
    defaultValues: {
      name: "",
      version: "",
      active: false,
      file: null,
    },
  });
  const [fileName, setFileName] = useState(null);
  const enterCounter = useRef(0);
  const [isDraggedUpon, setIsDraggedUpon] = useState(false);
  let { t } = useTranslation("common");
  const fileExtenstions = ['.hmlx', '.ifc'];
  const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const getFileNameWithoutExt = (fileName: string) => {
    return fileName.replace(/\.[^/.]+$/, "");
  }

  const handleDataSubmit = (data: UploadModelDialogData, event: any) => {
    event.data = data;
    if (onSubmit) { onSubmit(event); }
  };

  const handleClose = () => {
    if (isUploading()) {
      return;
    }

    enterCounter.current = 0;
    setIsDraggedUpon(false);
    handleReset();
    if (props.onClose) props.onClose(null, "backdropClick");
  };

  const onInvalidSubmit = (errors: any, event: any) => {
    event.preventDefault();
    console.error("Invalid submit", errors);
  };

  const handleReset = () => {
    reset();
    setFileName(null);
  };

  const handleDragEnter = (e: any) => {
    if (isUploading()) {
      return;
    }

    e.preventDefault();
    ++enterCounter.current;
    if (!isDraggedUpon) {
      setIsDraggedUpon(true);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: any) => {
    if (isUploading()) {
      return;
    }

    e.preventDefault();
    --enterCounter.current;
    if (enterCounter.current === 0) {
      setIsDraggedUpon(false);
    }
  };

  const handleDrop = (e: any) => {
    if (isUploading()) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    enterCounter.current = 0;
    setIsDraggedUpon(false);
    if (e.dataTransfer?.files?.length > 0) {
      let file = e.dataTransfer.files[0];
      if (file && !fileExtenstions.includes(`.${file.name.split('.').pop()}`)) {
        setSnackBar({ open: true, severity: "error", message: t("ModelsPanel.WrongFileType", { fileTypes: fileExtenstions?.toString() }) });
      } else if (file) {
        setValue("file", file, { shouldDirty: true });
        setValue("name", getFileNameWithoutExt(file.name));
        setFileName(getFileNameWithoutExt(file.name));
      }
    }
  };

  const handleFileChange = (file: File) => {
    if (isUploading()) {
      return;
    }

    if (file) {
      setValue("file", file, { shouldDirty: true });
      setValue("name", getFileNameWithoutExt(file.name));
      setFileName(getFileNameWithoutExt(file.name));
    }
  };

  const getDialogAction = () => {
    if (onUploadProgress) {
      return <Box width="100%">
        <LinearProgress variant={onAutomationServiceStarting ? "indeterminate" : "determinate"} sx={{ margin: "6px 0" }} value={onUploadProgress} />
        {
          onAutomationServiceStarting
            ? <Typography className={styles["automation-service"]} variant="body2" color="textSecondary">{t("ModelsPanel.StartingService")}</Typography>
            : <Typography variant="body2" color="textSecondary">{t("ModelsPanel.UploadProgress", { progress: onUploadProgress })}</Typography>
        }
      </Box>
    } else {
      return <Button variant='contained' type='submit'>{t("ModelsPanel.Submit")}</Button>
    }
  }

  const isUploading = (): boolean => {
    return onUploadProgress || onAutomationServiceStarting;
  };

  return (
    <DialogBox aria-labelledby={props['aria-labelledby']} open={props.open} onClose={handleClose} onDrop={handleDrop} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} {...dialogProps}>
      <form className={`${styles['dialog-content-container']} ${isDraggedUpon ? styles['dialog-content-container-dragged'] : ''}`} onSubmit={handleSubmit(handleDataSubmit, onInvalidSubmit)} onReset={handleReset}>
        <DialogTitle>
          Add Model
          <IconButton disabled={isUploading()} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormInputFile disabled={isUploading()} name="file" fileTypes={fileExtenstions} onChange={handleFileChange} control={control} rules={{ required: "File is required!" }} helpertext={"Upload a file"} label="Upload a model" setValue={setValue} classes={{ container: isUploading() ? styles['file-input-disabled'] : styles['file-input'] }} helperText={fileName ? fileName : t("ModelsPanel.FileInput")} helperIcon={fileName ? <InsertDriveFileIcon /> : <CloudUploadIcon />} />
          <FormInputText disabled={isUploading()} InputProps={{ type: "text" }} name="name" control={control} errortext={"Enter the name"} rules={{ required: "Name is required!" }} label={"Model Name"} sx={{ marginBottom: '12px' }} />
          <FormInputText disabled={isUploading()} InputProps={{ type: "text" }} name="version" control={control} errortext={"Enter the version"} rules={{ required: "Revision is required!" }} label={"Revision"} sx={{ marginBottom: '12px' }} />
        </DialogContent>
        <DialogActions>
          {getDialogAction()}
        </DialogActions>
      </form>
      <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
    </DialogBox>
  )
}

interface DeleteDialogProps {
  open: boolean
  onClose?: React.ReactEventHandler<{}>;
  modelName: string;
  data?: Model | Revision;
  onConfirm?: (data: any) => void;
}

function DeleteDialog(props?: DeleteDialogProps) {
  const { t } = useTranslation('common');
  let { open, onClose, onConfirm, data, modelName } = props ?? { open: false };
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const onConfirmChange = (event) => {
    if ((new RegExp('DELETE', 'i')).test(event.target.value)) {
      setDeleteConfirmed(true);
    } else {
      setDeleteConfirmed(false);
    }
  }

  useEffect(() => {
    setDeleteConfirmed(false);
  }, [open]);

  return (
    <DialogBox aria-labelledby='delete-model-dialog' open={open} onClose={onClose}>
      <DialogTitle variant={'error'}>
        {`Delete ${modelName}`}
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {t('ModelsPanel.DeleteHelp')}
        <TextField sx={{ display: 'block', marginTop: '10px' }} onChange={onConfirmChange} />
      </DialogContent>
      <DialogActions>
        <Button type='submit' color="error" variant="contained" onClick={() => { onConfirm(data) }} disabled={!deleteConfirmed}>{t('ModelsPanel.Delete')}</Button>
      </DialogActions>
    </DialogBox>
  )
}

export default function ModelsPanel(props: ModelsPanelProps) {
  let { open, onClose, models, project, onModelRemoved, onModelAdded } = props;
  const { getSession } = useSession();
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] = useState<DeleteDialogProps>(null);
  const [cnt, triggerRerender] = useState(0);
  const { t } = useTranslation('common');
  const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [uploadProgress, setUploadProgress] = useState(null);
  const [automationServiceStarting, setAutomationServiceStarting] = useState(false);

  const handleModelDialogClose = (e: any, data?: any) => {
    if (uploadProgress || automationServiceStarting) {
      return;
    }

    setOpenModelDialog(false);
  }

  const handleModelDialogSubmit = (e) => {
    let { data }: { data: UploadModelDialogData } = e;
    if (!data) {
      return;
    }

    const currentSession: IShareSession = getSession()
    try {
      let filePath = (project.datastoreFolder + '/temp/modelFiles/' + data.file.name).replaceAll(' ', '');
      nodeLoaderService.addFile(filePath, data.file, (progressEvent: any) => {
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }).then((res) => {
        setAutomationServiceStarting(true);
        automationService.addModelAutomationService(project, data.name, data.version, data.file, filePath, currentSession.token).then((automation) => {
          onModelAdded();
          setShowSnackbar(true);
          setSnackbarMessage("Your model has been added successfully.");
          setSnackbarSeverity('success');
        }).catch((err) => {
          setShowSnackbar(true);
          setSnackbarMessage("There was an error while uploading your model");
          setSnackbarSeverity('error');
        }).finally(() => {
          setOpenModelDialog(false);
          setAutomationServiceStarting(false);
          setUploadProgress(null);
        });
      }).catch((err) => {
        setUploadProgress(null);
        setShowSnackbar(true);
        setSnackbarMessage("There was an error while uploading your model");
        setSnackbarSeverity('error');
      });
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage("There was an error while uploading your model");
      setSnackbarSeverity('error');
    }
  }

  const handleModelDelete = (e: any, model: Model) => {
    e.stopPropagation();
    setDeleteDialogProps({ open: true, data: model, onClose: () => setDeleteDialogProps({ open: false, modelName: model.activeRevision.modelName }), modelName: model.activeRevision.modelName, onConfirm: handleModelDeleteConfirmed });
  }

  const handleRevisionDelete = (e: any, revision: Revision) => {
    e.stopPropagation();
    setDeleteDialogProps({ open: true, data: revision, onClose: () => setDeleteDialogProps({ open: false, modelName: revision.modelName }), modelName: revision.modelName, onConfirm: handleRevisionDeleteConfirmed });
  }

  const handleModelDeleteConfirmed = (model: Model) => {
    setDeleteDialogProps(null);
    let modelID = model.revisions[0]?.modelId;
    let promises = model.revisions.map((rev) => rev.node);
    promises = promises.map((node) => new ShareData(node, project, "hsbshare.projectmodel", null))
    promises = promises.map((sharedata) => nodeLoaderService.deleteModelNode(sharedata));
    Promise.all(promises).then((results) => {
      setSnackbarMessage(t('ModelsPanel.ModelDeleted', { name: modelID }));
      setSnackbarSeverity('success');
      setShowSnackbar(true);
      delete project.HsbModels[modelID];
      if (onModelRemoved)
        onModelRemoved();
    }).catch((err) => {
      console.error(err);
      setSnackbarMessage(t('ModelsPanel.FailedDeleteModel'));
      setSnackbarSeverity('error');
      setShowSnackbar(true);
    })
  }

  const handleRevisionDeleteConfirmed = (revision: Revision) => {
    let node = revision.node;
    if (node) {
      let shareData = new ShareData(node, project, "hsbshare.projectmodel", null);
      nodeLoaderService.deleteModelNode(shareData).then((result) => {
        setSnackbarMessage(t('ModelsPanel.RevisionDeleted', { name: revision.revision }));
        setSnackbarSeverity('success');
        setShowSnackbar(true);
        let model = models[revision.modelName];
        if (!model)
          return;
        model.revisions = model.revisions.filter((rev) => rev._id !== revision._id);

        if (model.revisions.length === 0) {
          if (onModelRemoved)
            onModelRemoved();
          delete models[revision.modelName];
        } else if (revision.active) {
          model.revisions[0].active = true;
          let activeShareData = new ShareData(model.revisions[0].node, project, "hsbshare.projectmodel", null);
          nodeLoaderService.updateModelNode(shareModelDefinition, activeShareData);
        }
      }).catch((err) => {
        console.error(err);
        setSnackbarMessage(t('ModelsPanel.FailedDeleteRevision'));
        setSnackbarSeverity('error');
        setShowSnackbar(true);
      }).finally(() => {
        triggerRerender(cnt + 1);
      })
    } else {
      setSnackbarMessage(t('Could not find model in project'));
      setSnackbarSeverity('error');
      setShowSnackbar(true);
    }
    setDeleteDialogProps(null);
  }

  const getModelElements = () => {
    return Object.entries(models).filter(([key, value]) => value.revisions?.some((r) => r.active)).map(([modelID, model], index) => {
      return <ModelItem key={modelID} project={project} model={model} handleModelDelete={handleModelDelete} handleRevisionDelete={handleRevisionDelete} />;
    })
  }

  const getModelsPanelActions = () => {
    return (
      <Box className={styles.modelActions}>
        <Button key="AddModelButton" variant='contained' onClick={() => { setOpenModelDialog(true); }} startIcon={<AddIcon />}>
          {t('ModelsPanel.AddModel')}
        </Button>
      </Box>
    )
  }

  const handleClose = (event: Event | React.SyntheticEvent | React.MouseEvent, reason?: string,) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <Tab open={open} direction={'left'} onClose={onClose} title={t("ModelsPanel.Models")} actions={getModelsPanelActions()}>
      {(models && open) &&
        getModelElements()}
      <UploadModelDialog key="AddModelDialog" open={openModelDialog} onClose={handleModelDialogClose} onSubmit={handleModelDialogSubmit} onUploadProgress={uploadProgress} onAutomationServiceStarting={automationServiceStarting} />
      {
        deleteDialogProps && <DeleteDialog {...deleteDialogProps} />
      }
      <Snackbar color={snackbarSeverity} open={showSnackbar} onClose={handleClose} autoHideDuration={6000}
        action={
          <Button color={snackbarSeverity} size="small" onClick={handleClose}>
            Ok
          </Button>}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Tab>
  )
}

export interface ModelsPanelProps {
  open: boolean;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  onModelAdded?: () => void;
  onModelRemoved?: () => void;
  onRevisionDisabled?: (revision: Revision) => void;
  onRevisionActivated?: (revision: Revision) => void;
  models: { [key: string]: Model };
  project: any;
  container?: Element | (() => Element);
}