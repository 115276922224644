
const taskManager = {
    errors: new Set<any>(),
    results: new Set<any>(),
    forkWithRetry: async (workItems: any[], numberOfAttempts = 3) => {
        taskManager.errors = new Set<any>();
        taskManager.results = new Set<any>();
        const executeAwaited: (workItems: Array<Awaited<Promise<any>>>) => Promise<any[]> = async (workItems) => {
            let promises: Promise<any>[] = workItems.map(func => func());
            const allSettledResult = await Promise.allSettled(promises);
            return allSettledResult.reduce((tryAgain, promiseResult, index) => {
                if (promiseResult.status === 'rejected') {
                    //it failed; so add to the try again list.
                    tryAgain.unshift(workItems[index]);
                    const errorMessage = promiseResult.reason?.response?.data?.message ?? promiseResult.reason;
                    taskManager.errors.add(errorMessage);
                } else {
                    taskManager.results.add(promiseResult.value);
                }
                return tryAgain;
            }, []);
        }
        let leftOver = await executeAwaited(workItems);
        let retry = 0;
        while (leftOver.length > 0 && retry++ < numberOfAttempts) {
            //it might fail, because of concurrent ghost reads in mongoose versioning.
            //the error is >>> error => error query addUserToTeamError adding teamAdmin to team: VersionError: No matching document found for id "632c2c2d670cb2a0dc22a512" version 12 modifiedPaths "admins"'
            //so we try again with less concurrency a maximum of three times.
            leftOver = await executeAwaited(leftOver);
        }
        return leftOver;
    }
}


export default taskManager;