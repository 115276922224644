import { KeyboardBackspace, Visibility, VisibilityOff } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import React, { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import restAPI from 'services/rest-api';
import styles from './LoginPopup.module.scss';

/**
 * This component is a popup dialog which provides the user with functions to either register, login or continue as a guest.
 * @param {LoginPopupProps} props The props for this component.
 * @returns The {@type {LoginPopup}} component as a JSX element
 */
export default function LoginPopup(props: LoginPopupProps) {
  let { open, setOpen, onLogin, onInvalidLogin, onClose: outOnClose } = props;
  const { t } = useTranslation('common')
  const [dialogState, setDialogState] = useState<'welcome' | 'login' | 'register'>('welcome');
  const [showPassword, setShowPassword] = useState(false);

  /**
   * The form for logging in.
   */
  const { control, handleSubmit, setError } = useForm<{ email: string, password: string }>({
    defaultValues:
    {
      email: "",
      password: "",
    }
  });

  /**
   * This function is called when the dialog closes
   * @param e 
   */
  const onClose = (e: BaseSyntheticEvent, reason: "backdropClick" | "escapeKeyDown" | "closeButton") => {
    setOpen(false);
    if (outOnClose)
      outOnClose(e, reason);
  }

  /**
   * Toggles whether passwords should eb shown
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Method prevents holding down the mouse button from interfering 
   * @param event 
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Method gets called whenever there is a vlid submit for the login form
   * @param e 
   * @returns 
   */
  const onValidSubmit = (e: any) => {
    let { email, password } = e;
    if (!email || !password)
      return;
    restAPI.login(email, password).then((res) => {
      if (onLogin)
        onLogin(res);
      setOpen(false);
    }).catch(err => {
      if (onInvalidLogin)
        onInvalidLogin(err);
      setError('password', { type: 'custom', message: t('LoginPopup.InvalidLogin') });
    });
  }

  /**
   * Output for the login dialog
   */
  return (
    <Dialog id='loginPopupDialog' className={styles['loginDialog']} open={open} onClose={onClose}>
      <DialogTitle>
        <Box className={styles['titleBox']}>
          <img alt="hsbShare logo" src="/img/header.svg" width={60} />
          <Typography variant='h4'>
            {t("LoginPopup.Title")}
          </Typography>
          <Typography variant='h5'>
            {t("LoginPopup.Subtitle")}
          </Typography>
          <Button id='closeButton' classes={{ root: styles['closeButton'] }} onClick={(e) => onClose(e, 'closeButton')}>
            <Close color='primary' />
          </Button>
          {dialogState !== 'welcome' &&
            <Button id='backButton' classes={{ root: styles['backButton'] }} onClick={() => { setDialogState('welcome') }}>
              <KeyboardBackspace color='primary' />
            </Button>}
        </Box>
      </DialogTitle>
      <DialogContent className={styles['dialogContent']}>
        {/* Main content */}
        {dialogState === 'welcome' &&
          <Box id='mainContent' className={styles['container']}>
            <Button variant='contained' href='/create-account' >
              <Typography color={'secondary'}>
                {t('LoginPopup.Continue.Email')}
              </Typography>
            </Button>
            <Button variant='contained' color='secondary' onClick={(e) => onClose(e, 'closeButton')}>
              <Typography >
                {t('LoginPopup.Continue.Guest')}
              </Typography>
            </Button>
            <Box className={styles['goToLoginContainer']}>
              <Trans t={t} key={"LoginPopup.GoToLogin"} variant='body2' >
                Already have an account? <Button variant='text' className={styles['loginButton']} onClick={() => { setDialogState('login') }}>Log in</Button>
              </Trans>
            </Box>
          </Box>}
        {/* Login form */}
        {dialogState === 'login' &&
          <form id='loginContent' className={styles['container']} onSubmit={handleSubmit(onValidSubmit)}>
            <FormInputText rules={{ 'required': t('LoginPopup.EmailRequired'), minLength: 1 }} InputProps={{ type: 'email' }} label={t('LoginPopup.Email')} name={"email"} control={control} />
            <FormInputText rules={{ 'required': t('LoginPopup.PasswordRequired'), minLength: 1 }}
              InputProps={{
                type: showPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }} label={t('LoginPopup.Password')} name={"password"} control={control} />

            <Button variant='contained' type='submit'>
              <Typography variant='body2' color={'secondary'}>
                {t('LoginPopup.Login')}
              </Typography>
            </Button>
          </form>}
        <Box className={styles['footer']}>
          <Typography variant={'caption'}>
            <Trans t={t} key={"LoginPopup.TOS"}>
              By continuing you agree to hsbShare's <Link href="/terms">Terms of Service</Link>, <Link href="/terms?tab=privacy">Privacy Policy</Link> and <Link href="/terms?tab=cookie">Cookie use</Link>
            </Trans>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

/**
 * Props for the {{@type {LoginPopup}}
 */
export interface LoginPopupProps {
  /**
   * If true the dialog will be opened
   */
  open: boolean;
  /**
   * The state setter to control whether this dialog is open.
   */
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Callback after the login has successfully happened
   * @param e Login event
   */
  onLogin?: (e) => void;
  /**
  * Callback after the login occurred with an error
  * @param e Login event
  */
  onInvalidLogin?: (e) => void;
  /**
  * Callback after the dialog was closed
  * @param e Login event
  */
  onClose?: (e, reason?) => void;
}
