import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogActions, DialogContent, IconButton, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './AnnouncementBar.module.scss';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { Close } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import useSession from 'hooks/useSession';

function AnnouncementBar() {
    const { t } = useTranslation('common');
    const { getSession } = useSession();
    // Set localtion for cookie
    const cookieLocation = `hsbshare.migration.${getSession().userid}`
    const [open, setOpen] = useState(false);
    const [cookies, setCookie] = useCookies([cookieLocation]);

    useEffect(() => {
        if (cookies[cookieLocation] !== 'true') setOpen(true);

        return () => { };
    }, [cookieLocation, cookies]);

    const handleClose = () => {
        // Set cookie for 1 month
        let currDate = new Date();
        currDate.setMonth(currDate.getMonth() + 1);
        setCookie(cookieLocation, true, { expires: currDate });
        setOpen(false);
    };

    return (
        <Dialog
            tabIndex={-1}
            open={open}
            id="AnnouncementBar"
            hideBackdrop
            classes={{
                container: styles['announcement-container'],
                root: styles['announcement-root'],
                paper: styles['announcement-paper'],
            }}>
            <DialogContent classes={{ root: styles['announcement-content'] }}>
                <Box id="announcement-content-logo" className={styles['announcement-content-logo']}>
                    <Typography className={styles['announcement-content-logo-text']}>3</Typography>
                </Box>
                <Box id="announcement-content-message" className={styles['announcement-content-message']}>
                    <Typography>{t('AnnouncementBar.Share3')}</Typography>
                    <Typography>
                        <Trans
                            t={t}
                            key={'AnnouncementBar.Legacy'}
                            components={{ 1: <Link underline={'none'} target="_blank" rel="noopener" href={'https://legacy.hsbshare.com'}></Link> }}>
                            {'The old version can still be found <1>here</1>'}
                        </Trans>
                    </Typography>
                </Box>
                <Box id="announcement-content-actions" className={styles['announcement-content-actions']}>
                    <FontAwesomeIcon icon={faCubes} />
                    <Link target="_blank" rel="noopener" href={'https://hsbcad.academy/new-hsbshare-version-3-0/'} underline={'none'}>
                        {t('AnnouncementBar.Academy')}
                    </Link>
                </Box>
            </DialogContent>
            <DialogActions classes={{ root: styles['announcement-actions'] }}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
}

export default AnnouncementBar;
