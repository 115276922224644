import { UseFormReturn } from "react-hook-form";
import { FormInputMatrixCheckbox } from '../Shared/FormComponents/FormInputMatrixCheckbox';
import { UserSettingsValues } from "./UserSettings";

export interface NotificationSettings {
    key: string;
    value: boolean;
    category: string;
    medium: string;
}

// Declare a flatten function that takes
// object as parameter and returns the
// flatten object
const flattenObj = (ob) => {
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {

        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {

                // Store temp in result
                result[i + '-' + j] = temp[j];
            }
        }

        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};

export default function NotificationSettingsPanel(props: NotificationSettingsPanelProps) {
    const { control } = props.useForm;
    const { values } = props;

    return (
        <div>
            <FormInputMatrixCheckbox name="notificationSettings"
                data={values ?
                    Object.entries(flattenObj(values))?.map(([key, value]) => {
                        let [medium, cat, label] = key.split('-');
                        if (!label) {
                            label = cat;
                            cat = undefined;
                        }

                        return {
                            column: medium,
                            row: label,
                            category: cat,
                            value: value
                        }
                    }) : null

                }
                control={control} />
        </div>
    )
}

export interface NotificationSettingsPanelProps {
    formId?: string;
    useForm: UseFormReturn<UserSettingsValues>;
    values: any;
}
