import { createContext } from 'react';

export interface ProjectInformation {
    createdOn: string,
    lastUpdatedOn: string,
    name: string,
    teamName: string,
    projectId: string,
    projectLink: string
}

export interface ProjectInformationContextType {
    projectInformation: ProjectInformation | null,
    setProjectInformation: (v: ProjectInformation) => void;
}

var projectInformation: ProjectInformation = null;

const setProjectInformation = (v) => {
    projectInformation = v;
}

export const ProjectInformationValue = {
    projectInformation: projectInformation,
    setProjectInformation: setProjectInformation
}

export const ProjectInformationContext = createContext<ProjectInformationContextType>({
    projectInformation: null,
    setProjectInformation: () => { }
})