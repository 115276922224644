
import BadgeIcon from '@mui/icons-material/Badge'
import FolderIcon from '@mui/icons-material/Folder'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WarningIcon from '@mui/icons-material/Warning'
import { Stack, Tooltip, useMediaQuery } from '@mui/material'
import teamRoleColWidthAtom from 'atoms/teamRoleColWidthAtom'
import teamRoleNameWidthAtom from 'atoms/teamRoleNameWidthAtom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

export default function TeamHeaderRole() {
    const { t } = useTranslation('common')
    const sm = useMediaQuery('(max-width:850px)')
    let [nameWidth, setNameWidth] = useRecoilState(teamRoleNameWidthAtom)
    let [colWidth, setColWidth] = useRecoilState(teamRoleColWidthAtom)

    useEffect(() => {
        const getWidth = (): string => {
            if (sm) return '80px'
            return '250px';
        }
        const getColWidth = (): number => {
            if (sm) return 40
            return 75
        }
        setNameWidth(getWidth())
        setColWidth(getColWidth())
    }, [nameWidth, colWidth, setColWidth, setNameWidth, sm])

    return (
        <Stack style={{ background: '#ffffff', boxShadow: '0 2px 2px -2px rgba(0, 0, 0, 0.4)', padding: '15px 0 15px 15px' }} direction="row" justifyContent="space-between" alignContent="center">
            <span style={{ width: nameWidth }}>{t('TeamRoles.Name')}</span>
            <Stack style={{ paddingRight: '16px' }} direction="row" justifyContent="flex-start" alignContent="center">
                <Tooltip title={t('TeamRoles.Administrator')?.toString()}>
                    <BadgeIcon color={'action'} style={{ width: `${colWidth}px` }} />
                </Tooltip>
                <Tooltip title={t('TeamRoles.Documents')?.toString()}>
                    <FolderIcon color={'action'} style={{ width: `${colWidth}px` }} />
                </Tooltip>
                <Tooltip title={t('TeamRoles.Issues')?.toString()}>
                    <WarningIcon color={'action'} style={{ width: `${colWidth}px` }} />
                </Tooltip>
                <Tooltip title={t('TeamRoles.TruckLoading')?.toString()}>
                    <LocalShippingIcon color={'action'} style={{ width: `${colWidth}px` }} />
                </Tooltip>
                <div style={{ width: `${colWidth}px` }}></div>
            </Stack>
        </Stack>
    )
}
