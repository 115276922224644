export function capitalizeFirstLetter(string) {
    if (!string)
        return undefined;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function onlyFirstLetterCapitalized(string) {
    if (!string)
        return undefined;
    return string.charAt(0).toUpperCase();
}

export function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
