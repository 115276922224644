import { Checkbox, Divider } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from '../../../services/stringUtils';
import styles from './FormInput.module.css';
import { FormInputMatrixCheckboxInstanceProps, FormInputMatrixCheckboxProps } from "./FormInputProps";



export const FormInputMatrixCheckbox: React.FC<FormInputMatrixCheckboxProps> = ({
    name,
    control,
    label,
    data
}) => {

    const { t } = useTranslation('common');
    const columns = data?.map((d) => d.column).filter((d, index, arr) => arr.findIndex((ele) => ele === d) === index) ?? [];
    const categories = data?.map((d) => d.category).filter((d, index, arr) => arr.findIndex((ele) => ele === d) === index) ?? [];
    const names = data?.map((d) => d.row).filter((d, index, arr) => arr.findIndex((ele) => ele === d) === index) ?? [];
    const nrRows = categories.length + names.length; // Header + cats + values
    const nrColumns = columns.length; // Side + media
    const rows = []
    categories?.forEach((cat) => {
        rows.push({ type: 'cat', value: cat });
        rows.push({ type: 'div', value: null });
        let arr1 = data?.filter((d) => d.category === cat);
        let arr2 = arr1.filter((d, index, arr) => arr.findIndex((ele) => ele.row === d.row) === index);
        let arr3 = arr2.map((d) => { return { type: 'label', value: d } });
        rows.push(...arr3);
    });



    const generateRows = (): JSX.Element[] => {
        let gridItems: JSX.Element[] = [];
        rows.forEach(({ type, value }, index) => {
            switch (type) {
                case 'cat': {
                    gridItems.push(
                        <div id="catDiv" className={styles.checkboxCategory} style={{ gridRowStart: index + 1, marginTop: 'auto', marginBottom: 'auto' }} key={"cat" + index}>
                            {t(capitalizeFirstLetter(name) + "." + capitalizeFirstLetter(value) + ".Title")}
                        </div>);
                    break;
                }
                case 'div': {
                    gridItems.push(
                        <Divider key={`divider-${index}`} style={{ gridRowStart: index + 1, gridColumnStart: 1, gridColumnEnd: nrColumns + 2, marginTop: 'auto', marginBottom: 'auto' }} />
                    );
                    break;
                }
                case 'label': {
                    gridItems.push(
                        <div id="labelDiv" className={styles.checkboxLabel} style={{ gridRowStart: index + 1, gridColumnStart: 1, marginTop: 'auto', marginBottom: 'auto' }} key={"label" + index}>
                            {t(capitalizeFirstLetter(name) + "." + capitalizeFirstLetter(value.category) + "." + capitalizeFirstLetter(value.row))}
                        </div>);
                    break;
                }
            }

        });
        if (data?.length > 0) {
            data.forEach((d: FormInputMatrixCheckboxInstanceProps) => {
                let rIndex = rows.findIndex((row) => row.value?.row === d.row && row.value?.category === d.category) + 2;
                let cIndex = columns.findIndex((column) => column === d.column) + 2;
                gridItems.push(
                    <Controller
                        name={(name + "." + d.column + (d.category ? ("." + d.category) : '') + "." + d.row)}
                        control={control}
                        key={"i-" + rIndex + "-" + cIndex}
                        // eslint-disable-next-line no-loop-func
                        render={({
                            field: { value, onChange, name }
                        }) => {
                            return (
                                <div id="matrixCheckDiv" style={{ gridColumnStart: cIndex, gridRowStart: rIndex - 1, margin: 'auto' }}>
                                    <Checkbox onChange={onChange} checked={value} />
                                </div>
                            )
                        }
                        } />);
            });
        }
        columns.forEach((row, index) => {
            gridItems.push(
                <div id="columnDiv" style={{ gridRowStart: 1, gridColumnStart: 2 + index, marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }} key={"c" + index}>
                    {t(capitalizeFirstLetter(name) + "." + capitalizeFirstLetter(row))}
                </div>);
        });
        return gridItems;
    }


    return (

        <div id="Matrix" className={styles.matrix}
            style={{
                gridTemplateColumns: `auto repeat(${nrColumns},50px)`,
                gridTemplateRows: `repeat(${nrRows},auto)`,
            }}>
            {generateRows()}
        </div>
    );


};