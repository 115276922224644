export type ProjectState = 'open' | 'on-hold' | 'completed';
export type IssueState = 'active' | 'resolved' | 'active-due' | 'closed';
export type ModelState = 'planned' | 'started' | 'complete' | 'superseded' | 'archived';
export const ModelStateValues: ModelState[] = ['planned', 'started', 'complete', 'superseded', 'archived'];
export type ActivityType = 'added' | 'edited' | 'deleted' | 'uploaded';

export const getChipLabel = (state: ProjectState | IssueState | ModelState, t: (text: string) => string) => {
    switch (state?.toLowerCase()) {
        case 'open': return t('ChipLabel.Open');
        case 'on-hold': return t('ChipLabel.OnHold');
        case 'completed': return t('ChipLabel.Completed');
        case 'active': return t('ChipLabel.Active');
        case 'resolved': return t('ChipLabel.Resolved');
        case 'active-due': return t('ChipLabel.ActiveDue');
        case 'closed': return t('ChipLabel.Closed');
        case 'planned': return t('ChipLabel.Planned');
        case 'started': return t('ChipLabel.Started');
        case 'complete': return t('ChipLabel.Complete');
        case 'superseded': return t('ChipLabel.Superseded');
        case 'archived': return t('ChipLabel.Archived');
        default: return t('ChipLabel.NotSet');
    }
}