import { Box, MenuItem, MenuItemProps, Typography } from '@mui/material';
import styles from './FormInputSelect.module.scss';

export interface FormInputSelectMenuItemProps extends MenuItemProps {
    type?: "iconLeft" | "iconRight";
    avatar?: {
        URI?: string,
        backgroundColor?: string,
        letter?: string,
        styleClass?: any
    };
    description?: string;
}

export const FormInputSelectMenuItem = (props: FormInputSelectMenuItemProps) => {
    const { type, avatar, description, ...menuItemProps } = props;
    return (
        <MenuItem key={props.key} className={`${styles['menu-item']} ${styles[type]}`} {...menuItemProps} classes={{ selected: styles['menu-item-selected'] }}>
            {avatar?.URI ?
                <img src={avatar.URI} alt={`Icon for ${menuItemProps.value}`} /> :
                (avatar?.backgroundColor || avatar?.styleClass) && <Box className={`${styles['menu-item-icon']} ${avatar?.styleClass}`} sx={{ backgroundColor: avatar.backgroundColor || null }}>
                    <span>{avatar.letter}</span>
                </Box>
            }
            <Box className={styles['menu-item-value']}>
                <Typography className={styles['menu-item-value-title']}>{menuItemProps.children || menuItemProps.value}</Typography>
                {
                    description &&
                    <Typography className={styles['menu-item-value-description']}>{description}</Typography>
                }
            </Box>
        </MenuItem>
    )
}
