import { Close } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import amITeamAdminAtom from 'atoms/amITeamAdmin'
import deletedTeamMemberAtom from 'atoms/deletedTeamMemberAtom'
import DialogBox from 'components/Shared/DialogBox'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import useSession from 'hooks/useSession'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { default as API, default as restAPI } from 'services/rest-api'
import { IShareSession } from 'types/IShareSession'
import editTeamAtom from '../../atoms/editTeamAtom'
import selectedTeamAtom from '../../atoms/selectedTeamAtom'
import useArray from '../../hooks/useArray'
import { ITeamItem, TeamItem } from './TeamItem'
import TeamList from './TeamList'

const TeamListController: React.FC<React.PropsWithChildren<{}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = () => {
    const { t } = useTranslation('common')
    const { getSession } = useSession();
    const getSessionCallback = useCallback(getSession, [getSession]);
    const [, setSelectedTeam] = useRecoilState(selectedTeamAtom)
    const [, setEditMode] = useRecoilState(editTeamAtom)
    const [filter, setFilter] = useState(null)
    const [activeTeam, setactiveTeam] = useState(null)
    const [teamToDelete, setTeamToDelete] = useState(null)
    const [teamToDeleteName, setTeamToDeleteName] = useState(null)
    const [roles, setRoles] = useState(null);
    const { items, setItems, add, update, remove } = useArray<ITeamItem>([])
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [deleteConfirmed, setDeleteConfirmed] = useState(false)
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' })
    let [, setAmITeamAdmin] = useRecoilState(amITeamAdminAtom);
    const [editTeam, setEditTeam] = useState(null);
    const deletedTeamMember = useRecoilValue(deletedTeamMemberAtom);

    useEffect(() => {
        if (getSession()?.userid === deletedTeamMember?.userId) {
            loadTeamData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletedTeamMember])

    const addTeam = () => {
        restAPI.createTeam('New Team').then((result) => {
            let team = result.data;
            let newTeam: ITeamItem = {
                teamID: result.data.teamID,
                isActive: false,
                name: 'New Team',
                teamPicture: {
                    URI: team.avatar.URI,
                    letter: 'N',
                    color: team.avatar.backgroundColor
                },
                admins: result.data.admins,
                users: result.data.users,
                isAdmin: true
            }
            add(newTeam);
            setSnackBar({ open: true, severity: 'success', message: t('TeamList.CreatedTeam', { newTeam: newTeam.name }) });
            setSelectedTeam({ teamID: newTeam.teamID, name: newTeam.name, selectedIndex: items.length });
            setEditMode(true);
            selectEditTeam(newTeam.teamID);
            setAmITeamAdmin(true);
        }).catch((err) => {
            console.error(err)
        });
    }

    const selectEditTeam = (teamId: string) => {
        setEditTeam(editTeam === teamId ? null : teamId);
    }

    const onFilter = async (query: string | null) => {
        setFilter(query?.toLowerCase());
    }

    const confirmDeleteTeam = (event: any, confirm: boolean) => {
        event?.preventDefault();
        if (confirm && teamToDelete) {
            restAPI.deleteTeam(teamToDelete).then((result) => {
                remove('teamID', teamToDelete)
                setTeamToDelete(null)
                setTeamToDeleteName(null)
                setSnackBar({ open: true, severity: 'success', message: t('TeamList.DeletedTeam', { teamName: teamToDeleteName }) })
            }).catch((err) => {
                setSnackBar({ open: true, severity: 'error', message: t('TeamList.FailedDelete') })
                setTeamToDelete(null)
                setTeamToDeleteName(null)
            }).finally(() => {
                setDeleteConfirmed(false);
            })
        }
        setShowDeleteDialog(false);
    }

    const onConfirmChange = (event) => {
        if ((new RegExp('DELETE', 'i')).test(event.target.value)) {
            setDeleteConfirmed(true);
        } else {
            setDeleteConfirmed(false);
        }
    }

    const handleDelete = (propNameId: string, id: string, teamName: string) => {
        setTeamToDelete(id)
        setTeamToDeleteName(teamName)
        setShowDeleteDialog(true)
    }

    const handleLeave = async (teamID: string) => {
        const teamMembers = await restAPI.getTeamMembers(teamID);
        const teamAdmins = teamMembers?.filter(teamMember => teamMember.isTeamAdmin);
        const currentSession: IShareSession = getSessionCallback()
        let isTeamAdmin = teamAdmins.find((user) => user.userId === currentSession.userid);
        //Check if last person on team
        if (isTeamAdmin) {
            let otherAdmins = teamAdmins.filter((user) => user.userId !== currentSession.userid);
            let otherUsers = teamMembers.filter((user) => user.userId !== currentSession.userid);
            if (otherAdmins.length === 0) {
                if (otherUsers.length === 0) {
                    setSnackBar({ open: true, severity: 'error', message: t('TeamList.OnlyUser') })
                    return;
                }
                else {
                    setSnackBar({ open: true, severity: 'error', message: t('TeamList.1Admin') })
                    return
                }
            }
        }
        restAPI.leaveTeam(teamID).then((res) => {
            remove('teamID', teamID)
            loadTeamData(true)
            setSnackBar({ open: true, severity: 'success', message: t('TeamList.LeftTeam') })
        }).catch((err) => {
            console.error(err);
            setSnackBar({ open: true, severity: 'error', message: t('TeamList.FailedLeave') })
        })
    }

    const loadTeamData = (mounted: Boolean) => {
        let promises = [
            API.getUserActiveTeam(),
            API.getUserTeams(),
            API.getUserRoles()
        ];
        Promise.all(promises).then(([activeTeamResponse, teamsResponse, rolesResponse]) => {
            if (!mounted)
                return;
            //const selectedIndex = teamsResponse?.data?.teams?.findIndex(team => team.teamID === activeTeamResponse.data.teamID)
            //const isTeamAdmin = teamsResponse?.data?.teams?.[selectedIndex]?.isAdmin;
            const selectedIndex = 0;
            const isTeamAdmin = teamsResponse?.data?.teams?.[selectedIndex]?.isAdmin;

            setAmITeamAdmin(isTeamAdmin);
            setItems(teamsResponse.data.teams);
            setactiveTeam(activeTeamResponse.data)
            setSelectedTeam(teamsResponse.data.teams.length > 0 ? { teamID: teamsResponse.data.teams[0].teamID, name: teamsResponse.data.teams[0].name, selectedIndex: 0 } : null);
            setRoles(rolesResponse)
        }).catch(err => {
            console.error(err);
        });
    };

    useEffect(() => {

        let mounted = true;

        loadTeamData(mounted);

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        setShowDeleteDialog(false);
        setDeleteConfirmed(false);
    }

    return (
        <Box style={{ height: '100%' }}>
            <TeamList addTeam={addTeam} onFilter={onFilter} roles={roles} >
                {items.filter((t) => { return !filter || t.name.toLowerCase().includes(filter, 0) }).map((team, index) => {
                    return (
                        <TeamItem key={team.teamID}
                            teamID={team.teamID}
                            isActive={team.teamID === activeTeam?.teamID}
                            isAdmin={team.isAdmin}
                            teamPicture={team.avatar}
                            name={team.name}
                            index={index}
                            update={update}
                            remove={handleDelete}
                            onLeave={handleLeave}
                            isEdit={team.teamID === editTeam}
                            setEditMode={selectEditTeam}
                        />)
                })}
            </TeamList>
            <DialogBox aria-labelledby="delete-team-dialog" open={showDeleteDialog} onClose={handleClose}>
                <form>
                    <DialogTitle variant={'error'}>
                        Delete Team
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this team? This can not be reversed. Please type 'DELETE' in the field below to continue.
                        <TextField sx={{ display: 'block', marginTop: '10px' }} onChange={onConfirmChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="error" onClick={(event) => { confirmDeleteTeam(event, true) }} disabled={!deleteConfirmed}>Delete</Button>
                    </DialogActions>
                </form>
            </DialogBox>
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </Box>
    )
}

export default TeamListController;