import { Avatar, Box, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { compareDesc, formatDistanceToNow } from 'date-fns';
import React, { useState, useRef, useEffect } from 'react';
import styles from 'styles/NotificationDialog.module.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import AddIcon from '@mui/icons-material/Add';
import { blue, green, orange, red, yellow } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toTitleCase } from 'services/stringUtils';

interface Notification {
    author: string;
    subject: string;
    action: 'upload' | 'edited' | 'tagged' | 'removed' | 'comment' | 'added';
    parent: string
    date: string;
    thumbnail?: string;
}

function NotificationSkeleton(): JSX.Element {
    return (
        <MenuItem key={"notif-skeleton"}>
            <Box className={styles.notificationContainer}>
                <Box gridArea="thumbnail" className={styles.thumbnailContainer}>
                    <Skeleton variant='rectangular' height={'64px'} />
                </Box>
                <Typography variant='h5' gridArea="subject">
                    <Skeleton variant='text' />
                </Typography>
                <Typography variant='caption' gridArea="project">
                    <Skeleton variant='text' />
                </Typography>
                <Typography variant='caption' gridArea="date">
                    <Skeleton variant='text' />
                </Typography>
                <div style={{ gridArea: "symbol" }}>
                    <Skeleton variant='circular' width={'32px'} height={'32px'} />
                </div>
            </Box>
        </MenuItem>
    )
}

export default function NotificationDialog(props: NotificationDialogProps) {
    const { t } = useTranslation('common');
    const [amountNotifs, setAmountNotifs] = useState(10);
    const scrollbarRef = useRef<PerfectScrollbar>(null);
    const [loading, setLoading] = useState(false);
    const [postLoadScroll, setPostLoadScroll] = useState(false);
    const loadingOperation = useRef<NodeJS.Timeout>();
    const preLoadingOperation = useRef<NodeJS.Timeout>();

    const fetchedNotifications = () => {
        let sortedNotifs = demoNotifs.sort((a, b) => { return compareDesc(new Date(a.date), new Date(b.date)) });
        return (sortedNotifs.slice(0, amountNotifs));
    }

    const handleClose = (event: React.MouseEvent) => {
        if (loadingOperation.current) {
            clearTimeout(loadingOperation.current);
            loadingOperation.current = null;
        }
        if (preLoadingOperation.current) {
            clearTimeout(preLoadingOperation.current);
            preLoadingOperation.current = null;
        }
        setAmountNotifs(10);
        setLoading(false);
        setPostLoadScroll(false);
        if (props.onClose)
            props.onClose(event);
    }

    /**
     * Loads additional notifications.
     * @param event 
     */
    const handleLoad = () => {
        /**
         * @todo Implement additional notification batch get in API 
         */
        setLoading(true);
        // Fake API fetch with timeout
        if (loadingOperation.current)
            return;
        loadingOperation.current = setTimeout(() => {
            setLoading(false);
            setAmountNotifs(amountNotifs + 10);
            loadingOperation.current = null;
        }, 3000);
    }

    // Check if we reached the current end of the scroll bar before loading more
    const handleScrollEnd = (e: any) => {
        if (!props.open || loading || postLoadScroll)
            return;
        if (!preLoadingOperation.current) {
            preLoadingOperation.current = setTimeout(() => {
                setPostLoadScroll(true);
                preLoadingOperation.current = null;
                handleLoad();
            }, 200);
        }
    }

    // We need to check if we scrolled up to abort the loading
    const handleScrollUp = (e: any) => {
        if (!props.open)
            return;
        if (preLoadingOperation.current) {
            clearTimeout(preLoadingOperation.current);
            preLoadingOperation.current = null;
        }
    }

    // We need to check if we scrolled down again after loading
    // Prevents immediately triggering another load after the previous one finished
    const handleScrollDown = (e: any) => {
        if (!props.open)
            return;
        if (postLoadScroll) {
            setPostLoadScroll(false);
        }
    }

    /**
     * Handles a notification that has been clicked => redirect to notification subject
     * @param notif 
     */
    const handleNotificationClick = (notif: Notification) => {
        /**
         * @todo IMplement redirect to notification subject!
         */
        console.warn("Clicked on notif but redirect not yet implemented:", notif);
        if (loadingOperation.current) {
            clearTimeout(loadingOperation.current);
            loadingOperation.current = null;
        }
        if (preLoadingOperation.current) {
            clearTimeout(preLoadingOperation.current);
            preLoadingOperation.current = null;
        }
        setAmountNotifs(10);
        setLoading(false);
        setPostLoadScroll(false);
        props.onClose(null);
    }

    const notificationIcon = (action: 'upload' | 'edited' | 'tagged' | 'removed' | 'comment' | 'added') => {
        const colorValue = 300;
        const size = '32px'
        const iconSize = '20px'
        switch (action) {
            case 'upload': return (
                <Avatar sx={{ bgcolor: green[colorValue], width: size, height: size }} >
                    <CloudUploadIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
            case 'added': return (
                <Avatar sx={{ bgcolor: green[colorValue], width: size, height: size }} >
                    <AddIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
            case 'edited': return (
                <Avatar sx={{ bgcolor: yellow[colorValue], width: size, height: size }} >
                    <EditIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
            case 'tagged': return (
                <Avatar sx={{ bgcolor: blue[colorValue], width: size, height: size }} >
                    <AlternateEmailIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
            case 'removed': return (
                <Avatar sx={{ bgcolor: red[colorValue], width: size, height: size }} >
                    <DeleteIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
            case 'comment': return (
                <Avatar sx={{ bgcolor: orange[colorValue], width: size, height: size }} >
                    <CommentIcon sx={{ fontSize: iconSize }} />
                </Avatar>
            );
        }
    }

    /**
     * Creates a list of Menu Items for the provided array of notifications.
     * @param notifications Array of notification data to insert into the element
     * @returns {JSX.Element} Array of JSX.Elements for each notification
     */
    const notificationEntries = (notifications: Notification[]) => {
        try {
            return notifications.map((sortedNotif, index) => {
                return (
                    <MenuItem key={"notif-" + index} onClick={() => { handleNotificationClick(sortedNotif) }}>
                        <Box className={styles.notificationContainer}>
                            <Box gridArea="thumbnail" className={styles.thumbnailContainer}>
                                {sortedNotif.thumbnail &&
                                    <img src={sortedNotif.thumbnail} alt={"thumbnail of the project"} />}
                            </Box>
                            <Typography variant='h5' gridArea="subject">
                                {sortedNotif.subject}
                            </Typography>
                            <Typography variant='caption' gridArea="project">
                                {toTitleCase(sortedNotif.parent)}
                            </Typography>
                            <Typography variant='caption' gridArea="date">
                                {formatDistanceToNow(new Date(sortedNotif.date))}  {t('notificationDialog.AgoBy')} {toTitleCase(sortedNotif.author)}
                            </Typography>
                            <div style={{ gridArea: "symbol" }}>
                                {notificationIcon(sortedNotif.action)}
                            </div>
                        </Box>
                    </MenuItem>
                )
            })

        }
        catch (err) {
            console.error(err);
            return null;
        }
    }


    useEffect(() => {
        if (props.open) {
            setLoading(false);
            setTimeout(() => {
                scrollbarRef.current?.updateScroll();
            }, 200);
        }
    }, [props.open])

    return (
        <div>
            <Menu open={props.open}
                onClose={handleClose}
                anchorEl={props.anchor}
                classes={{ paper: styles.notificationDialog, list: styles.notificationDialogList }}
            >
                <MenuItem key="notif-title" className={styles.notificationTitle} disabled>
                    {t('notificationDialog.Notifications')}
                </MenuItem>
                <PerfectScrollbar ref={scrollbarRef} style={{ height: '45vh', position: 'relative' }} options={{ suppressScrollX: true }}
                    onYReachEnd={handleScrollEnd}
                    onScrollUp={handleScrollUp}
                    onScrollDown={handleScrollDown}>
                    {notificationEntries(fetchedNotifications())}
                    <NotificationSkeleton />
                </PerfectScrollbar>

            </Menu>
        </div>
    )
}

interface NotificationDialogProps {
    open: boolean;
    onClose: (event: React.MouseEvent) => void;
    anchor: Element | ((element: Element) => Element);
}

/**
 * THIS IS TEMP DATA TO DEMO THE IMPLEMENTATION
 */
// TODO: remove demo data
const demoNotifs: Notification[] = [
    {
        "author": "frank alberts",
        "subject": "Bill Test 9999",
        "parent": "HSB-12459",
        "action": "removed",
        "date": "Wed Oct 13 2021 15:19:03 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "List of objects.pdf",
        "parent": "HSB-12459",
        "action": "upload",
        "date": "Wed Oct 06 2021 06:19:01 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "Issue123",
        "parent": "Project xyz",
        "action": "comment",
        "date": "Tue Oct 12 2021 13:13:04 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "List of objects.pdf",
        "parent": "Project xyz",
        "action": "upload",
        "date": "Tue Oct 12 2021 05:52:55 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "gill gonnissen",
        "subject": "Issue123",
        "parent": "HSB-12459",
        "action": "added",
        "date": "Fri Oct 01 2021 10:26:41 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "Bill Test 9999",
        "parent": "HSB-12459",
        "action": "tagged",
        "date": "Sun Oct 03 2021 10:50:38 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "Bill Test 9999",
        "parent": "model 123",
        "action": "added",
        "date": "Fri Oct 08 2021 06:20:23 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "model 123",
        "parent": "Ateam",
        "action": "upload",
        "date": "Mon Oct 04 2021 04:56:21 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "model 123",
        "parent": "Model Anno",
        "action": "added",
        "date": "Sat Oct 09 2021 03:53:34 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "model 123",
        "parent": "Bill Test 9999",
        "action": "comment",
        "date": "Fri Oct 01 2021 18:47:12 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "Missing object",
        "parent": "Project xyz",
        "action": "upload",
        "date": "Sat Oct 02 2021 14:22:33 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "model 123",
        "parent": "HSB-12459",
        "action": "upload",
        "date": "Fri Oct 08 2021 11:57:14 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "Missing object",
        "parent": "Model Anno",
        "action": "comment",
        "date": "Sat Oct 02 2021 06:47:41 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "tempList.pdf",
        "parent": "model 123",
        "action": "edited",
        "date": "Sat Oct 02 2021 04:57:48 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "Missing object",
        "parent": "HSB-12459",
        "action": "tagged",
        "date": "Tue Oct 12 2021 15:42:15 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "tempList.pdf",
        "parent": "Ateam",
        "action": "comment",
        "date": "Tue Oct 12 2021 09:36:53 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "model 123",
        "parent": "Bill Test 9999",
        "action": "added",
        "date": "Mon Oct 11 2021 03:23:57 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "List of objects.pdf",
        "parent": "Project xyz",
        "action": "upload",
        "date": "Mon Oct 11 2021 02:08:06 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "gill gonnissen",
        "subject": "List of objects.pdf",
        "parent": "Project xyz",
        "action": "removed",
        "date": "Mon Oct 11 2021 12:14:55 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "tempList.pdf",
        "parent": "model 123",
        "action": "comment",
        "date": "Sat Oct 09 2021 18:04:42 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "model 123",
        "parent": "model 123",
        "action": "upload",
        "date": "Sat Oct 09 2021 05:58:19 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Ateam",
        "parent": "model 123",
        "action": "comment",
        "date": "Wed Oct 13 2021 14:59:37 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "tempList.pdf",
        "parent": "HSB-12459",
        "action": "upload",
        "date": "Wed Oct 13 2021 02:38:34 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "Missing object",
        "parent": "Project xyz",
        "action": "tagged",
        "date": "Thu Oct 07 2021 10:51:50 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "tempList.pdf",
        "parent": "model 123",
        "action": "added",
        "date": "Sun Oct 03 2021 07:49:13 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "gill gonnissen",
        "subject": "Missing object",
        "parent": "Bill Test 9999",
        "action": "edited",
        "date": "Fri Oct 01 2021 16:35:48 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Ateam",
        "parent": "Project xyz",
        "action": "edited",
        "date": "Fri Oct 01 2021 20:54:09 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "Bill Test 9999",
        "parent": "model 123",
        "action": "added",
        "date": "Fri Oct 01 2021 02:09:48 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "List of objects.pdf",
        "parent": "HSB-12459",
        "action": "comment",
        "date": "Wed Oct 13 2021 19:18:58 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "Ateam",
        "parent": "HSB-12459",
        "action": "edited",
        "date": "Fri Oct 01 2021 16:24:03 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "Bill Test 9999",
        "parent": "Bill Test 9999",
        "action": "upload",
        "date": "Sun Oct 10 2021 07:17:38 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "tempList.pdf",
        "parent": "model 123",
        "action": "edited",
        "date": "Wed Oct 06 2021 20:02:06 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "List of objects.pdf",
        "parent": "Bill Test 9999",
        "action": "tagged",
        "date": "Fri Oct 08 2021 23:08:32 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "gill gonnissen",
        "subject": "tempList.pdf",
        "parent": "model 123",
        "action": "tagged",
        "date": "Wed Oct 06 2021 03:10:58 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Missing object",
        "parent": "Ateam",
        "action": "comment",
        "date": "Fri Oct 08 2021 13:59:33 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "model 123",
        "parent": "Ateam",
        "action": "upload",
        "date": "Tue Oct 12 2021 09:33:28 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "tempList.pdf",
        "parent": "Project xyz",
        "action": "upload",
        "date": "Mon Oct 04 2021 04:36:52 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "Bill Test 9999",
        "parent": "HSB-12459",
        "action": "edited",
        "date": "Wed Oct 06 2021 02:05:09 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "model 123",
        "parent": "model 123",
        "action": "added",
        "date": "Sun Oct 10 2021 19:18:49 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Bill Test 9999",
        "parent": "Ateam",
        "action": "added",
        "date": "Tue Oct 12 2021 01:20:21 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "Ateam",
        "parent": "Bill Test 9999",
        "action": "tagged",
        "date": "Mon Oct 11 2021 10:25:56 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "frank alberts",
        "subject": "Missing object",
        "parent": "Bill Test 9999",
        "action": "edited",
        "date": "Sat Oct 09 2021 23:11:47 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "model 123",
        "parent": "Project xyz",
        "action": "comment",
        "date": "Wed Oct 13 2021 13:30:15 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "jonas blazinskas",
        "subject": "Issue123",
        "parent": "Project xyz",
        "action": "removed",
        "date": "Tue Oct 05 2021 01:30:33 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Missing object",
        "parent": "Bill Test 9999",
        "action": "upload",
        "date": "Mon Oct 11 2021 23:42:36 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "nicolas larrea",
        "subject": "Bill Test 9999",
        "parent": "Ateam",
        "action": "added",
        "date": "Tue Oct 05 2021 12:54:43 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "tempList.pdf",
        "parent": "Project xyz",
        "action": "tagged",
        "date": "Tue Oct 05 2021 17:55:14 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "zeno watteeuw",
        "subject": "List of objects.pdf",
        "parent": "Model Anno",
        "action": "tagged",
        "date": "Tue Oct 05 2021 09:18:22 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Ateam",
        "parent": "Model Anno",
        "action": "upload",
        "date": "Thu Oct 07 2021 17:41:50 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    },
    {
        "author": "bill malcolm",
        "subject": "Issue123",
        "parent": "HSB-12459",
        "action": "removed",
        "date": "Mon Oct 04 2021 12:34:12 GMT+0200 (Midden-Europese zomertijd)",
        "thumbnail": "http://place-hold.it/64x64"
    }
]