import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import folderStyles from './FolderElement.module.scss';
import documentStyles from './DocumentsPanel.module.scss';
import LinkIcon from '@mui/icons-material/Link';
import { useTranslation } from 'react-i18next';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import { createRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { useForm } from 'react-hook-form';
import DialogBox from 'components/Shared/DialogBox';
import { Close } from "@mui/icons-material";
import Requires from 'components/Shared/Permissions/Requires';

function FolderElement(props: FolderElementProps) {
    const { folder, onFolderSelection, onFolderDelete, onFolderDuplication, onFolderRename, projectId } = props;
    const { t } = useTranslation('common');
    const ref = createRef<HTMLDivElement>();
    const [isRenaming, setIsRenaming] = useState(false);
    const { control, getValues } = useForm<any>({
        defaultValues:
        {
            name: folder.properties.name.value,
        },
        mode: "onChange"
    });

    const handleFolderRename = () => {
        setIsRenaming(true);
    }

    const handleFolderRenameDone = () => {
        onFolderRename(folder._id, getValues('name'));
        setIsRenaming(false);
    }

    const getModelReference = () => {
        let totalAmount = 0;
        let singleReferenceId = null;

        for (const key in folder.modelReferences) {
            const values = folder.modelReferences[key];
            totalAmount += values.length;

            if (values.length === 1) {
                singleReferenceId = values[0].referenceId;
            }
        }

        return totalAmount === 1 ? singleReferenceId : `${totalAmount} entities`;
    }

    return (
        <Box>
            <Box ref={ref} className={documentStyles['document-container']} onClick={(e) => onFolderSelection(e, folder._id)}>
                <Box className={documentStyles['document-icon-wrapper']}>
                    <FolderIcon className={folderStyles['folder-icon']} />
                </Box>
                <Box className={documentStyles['document-information']}>
                    <Box>
                        <Box className={folderStyles['folder-linked-container']}>
                            <Typography className={documentStyles['document-name']}>{folder.properties.name.value}</Typography>
                            {
                                folder.modelReferences && Object.keys(folder.modelReferences).length > 0 &&
                                <Box className={folderStyles['folder-linked-wrapper']}>
                                    <LinkIcon className={folderStyles['linked-icon']} />
                                    <Typography className={folderStyles['linked-entity-name']}>{getModelReference()}</Typography>
                                </Box>
                            }
                        </Box>
                        <Typography className={documentStyles['folder-contents-amount']}>{`${folder.documents.length} ${folder.documents.length === 1 ? t('DocumentsPanel.Item') : t('DocumentsPanel.Items')}`}</Typography>
                    </Box>
                    <Box className={folderStyles['arrow-wrapper']}>
                        <ArrowRightIcon />
                    </Box>
                </Box>
            </Box>
            <Requires permissions={['document.edit']} projectId={projectId} >
                <ContextualMenu parent={ref}>
                    <ContextualMenuItem onClick={() => onFolderDuplication(folder._id)}>{t("DocumentsPanel.Duplicate")}</ContextualMenuItem>
                    <ContextualMenuItem onClick={handleFolderRename}>{t("DocumentsPanel.Rename")}</ContextualMenuItem>
                    <Divider></Divider>
                    <ContextualMenuItem variant='red' onClick={() => onFolderDelete(folder._id)}><DeleteIcon />{t("DocumentsPanel.Delete")}</ContextualMenuItem>

                </ContextualMenu>
            </Requires>
            <DialogBox open={isRenaming}>
                <DialogTitle>
                    {`${t("DocumentsPanel.Rename")} ${folder.properties.name.value}`}
                    <IconButton disabled={getValues('name')?.length === 0} onClick={() => setIsRenaming(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormInputText InputProps={{ type: "text" }} control={control} name={'name'}></FormInputText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type='submit' onClick={handleFolderRenameDone}>{t("DocumentsPanel.Submit")}</Button>
                </DialogActions>
            </DialogBox>
        </Box>
    )
}

export default FolderElement

export interface FolderElementProps {
    folder: any;
    onFolderSelection: (e: any, folderId: string) => void;
    onFolderDelete: (folderId: string) => void;
    onFolderDuplication: (folderId: string) => void;
    onFolderRename: (folderId: string, name: string) => void;
    projectId: string;
}
