import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge, Box, ClassNameMap, ClickAwayListener, IconButton, IconButtonClasses, Paper, Popper, TextFieldClasses, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputCheckbox } from '../../FormComponents/FormInputCheckbox';
import styles from './ListFilter.module.css';

export default function ListFilter({ onFilter, searchInterval, classes, filters }: ListFilterProps) {
    const { control, getValues, reset } = useForm()
    const [showFilter, setShowFilter] = useState(false);
    const anchor = useRef(null);
    const [filterValue, setFilterValue] = useState<string[]>([]);
    const { t } = useTranslation('common');

    const handleClick = (e: any) => {
        setShowFilter(!showFilter);
        anchor.current = (anchor.current ? null : e.target);
        let values = getValues();
        let value = Object.entries(values).filter(([key, value]) => value).map(([key, value]) => key)
        setFilterValue(value)
    }

    const handleChange = () => {
        let values = getValues();
        let value = Object.entries(values).filter(([key, value]) => value).map(([key, value]) => key)
        setFilterValue(value)
        onFilter(value);
    }

    const handleClickAway = (e: any) => {
        if (showFilter) {
            setShowFilter(false);
            anchor.current = null;
        }
    }

    useEffect(() => {
        let values = getValues();
        const result: { [key: string]: boolean } = filters.reduce((r, key: string) => {
            return {
                ...r,
                [key]: values[key] ?? false,
            }
        }, {} as { [key: string]: boolean });
        reset(result);

        return () => {
        };
    }, [filters, getValues, reset]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes?.root}>
                <Popper id='filterIconPaper' open={showFilter} anchorEl={anchor.current}
                    className={styles.filterPopper}>
                    <Paper classes={{ root: styles.filterPaper }}>
                        <form onChange={handleChange}>
                            {filters.map((filter) => {
                                return (
                                    <Box key={'check-' + filter} className={styles.checkBoxField}>
                                        <Typography variant='subtitle1' className={styles.checkBoxLabel}>
                                            {filter}
                                        </Typography>
                                        <FormInputCheckbox name={filter} control={control} />
                                    </Box>
                                )
                            })}
                        </form>
                    </Paper>
                </Popper>
                <Tooltip title={t("ListFilter.Filter")}>
                    <IconButton id='filterIconButton' onClick={handleClick} classes={classes?.icon} >
                        <Badge badgeContent={filterValue.length}>
                            <FilterListIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
        </ClickAwayListener>
    )
}

export interface ListFilterProps {
    /**
     * Will be called if there is a search value set.
     */
    onFilter: (queries: string[]) => void;
    /**
     * The search will wait this interval before searching after the user stopped typing.
     */
    searchInterval?: number;
    classes?: Partial<{ root: string, icon: Partial<IconButtonClasses> & Partial<ClassNameMap<never>>, popper: Partial<TextFieldClasses> }>
    filters: string[];
}
