import { Box, Divider, Typography } from '@mui/material';
import fileStyles from './FileElement.module.scss';
import documentStyles from './DocumentsPanel.module.scss';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { createRef, useState } from 'react';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import restAPI from '../../../services/rest-api';
import useSession from 'hooks/useSession';
import Requires from 'components/Shared/Permissions/Requires';

function FileElement(props: FileElementProps) {
    const { file, isMarkedForCut, handleCopyCutFile, handleRemoveFile, projectId } = props;
    const { t } = useTranslation('common');
    const ref = createRef<HTMLDivElement>();
    const [markedForCut, setMarkedForCut] = useState(isMarkedForCut);
    const { getSession } = useSession();

    const getFileIcon = () => {
        if (file.type?.includes('image')) {
            return <ImageIcon className={fileStyles['image-icon']} />;
        } else if (file.type?.includes('pdf')) {
            return <PictureAsPdfIcon className={fileStyles['pdf-icon']} />;
        } else {
            return <InsertDriveFileIcon className={fileStyles['file-icon']} />;
        }
    }

    const handleCutFile = () => {
        setMarkedForCut(true);
        handleCopyCutFile(file, true);
    }

    const handleFilePreview = () => {
        const url = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        window.open(url);
    }

    const handleFileDownload = () => {
        const url = window.URL.createObjectURL(
            new Blob([restAPI.getFileDownloadURL(file.path, getSession()?.token)]),
        );

        // Create link element to download file
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name,);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const getFileName = () => {
        return file.name || file.path.split("/").pop();
    }

    return (
        <Box ref={ref} className={`${documentStyles['document-container']} ${markedForCut ? fileStyles['marked-for-cut'] : null}`} onClick={handleFilePreview}>
            <Box className={documentStyles['document-icon-wrapper']}>
                {getFileIcon()}
            </Box>
            <Box>
                <Typography className={documentStyles['document-name']}>{getFileName()}</Typography>
                {
                    (file.size || file.uploadedAt) &&
                    <Box className={fileStyles['file-details-container']}>
                        <Typography className={documentStyles['folder-contents-amount']}>{file.size}</Typography>
                        <Box className={fileStyles['divider-dot']}></Box>
                        <Typography className={documentStyles['folder-contents-amount']}>{file.uploadedAt}</Typography>
                    </Box>
                }
            </Box>
            <ContextualMenu parent={ref}>
                <ContextualMenuItem onClick={handleFileDownload}>{t("DocumentsPanel.Download")}</ContextualMenuItem>
                <Requires permissions={['document.edit']} projectId={projectId} >
                    <>
                        <ContextualMenuItem onClick={() => handleCopyCutFile(file, false)}>{t("DocumentsPanel.Copy")}</ContextualMenuItem>
                        <ContextualMenuItem onClick={handleCutFile}>{t("DocumentsPanel.Cut")}</ContextualMenuItem>
                        <Divider></Divider>
                        <ContextualMenuItem variant='red' onClick={() => handleRemoveFile(file)}><DeleteIcon />{t("DocumentsPanel.Delete")}</ContextualMenuItem>
                    </>
                </Requires>
            </ContextualMenu>
        </Box>
    )
}

export default FileElement

export interface FileElementProps {
    folder: any;
    file: any;
    isMarkedForCut: boolean;
    projectId: string;
    handleCopyCutFile: (file: any, isCut: boolean) => void;
    handleRemoveFile: (file: any) => void;
}
