import Search from '@mui/icons-material/Search';
import { Box, ClassNameMap, IconButton, IconButtonClasses, TextField, TextFieldClasses, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styles from './ListSearch.module.scss';

export default function ListSearch({ onFilter, searchInterval, classes }: ListSearchProps) {
    const [showSearch, setShowSearch] = useState(false);
    const searchFieldRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout>(null);
    const [filterValue, setFilterValue] = useState('');
    const { t } = useTranslation('common');

    /**
     * 
     * @param e Change event
     */
    const handleChange = async (e: any) => {
        let filter = (e.target.value && e.target.value !== '') ? e.target.value : null;
        setFilterValue(filter);
        if (typingTimer)
            clearTimeout(typingTimer);

        setTypingTimer(setTimeout((filter) => {
            onFilter(filter);
            setTypingTimer(null);
        }, searchInterval ?? 500, filter))
    }

    const handleClick = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSearch(!showSearch);
        // If true (will be false on rerender) clear filter
        if (showSearch) {
            onFilter(null);
            clearTimeout(typingTimer);
            setTypingTimer(null);
        }
        else {
            searchFieldRef.current?.focus();
        }
    }

    const handleSearchBlur = (e: any) => {
        if (e.relatedTarget?.id === 'searchIconButton')
            return;
        if (!filterValue || filterValue === '') {
            onFilter(null);
            setShowSearch(false);
            setFilterValue(null);
            clearTimeout(typingTimer);
            setTypingTimer(null);
        }
    }

    const handleEntered = () => {
        inputRef.current?.focus();
        inputRef.current?.select();
    }

    const handleExited = () => {
        inputRef.current?.blur();
    }

    const handleEndListener = (done) => {
        searchFieldRef.current?.addEventListener("transitionend", done, { once: true });
    }

    return (
        <Box className={classes?.root}>
            <CSSTransition in={showSearch}
                nodeRef={searchFieldRef}
                classNames={{
                    enter: styles['field-enter'],
                    enterActive: styles['field-enter-active'],
                    enterDone: styles['field-enter-done'],
                    exit: styles['field-exit'],
                    exitActive: styles['field-exit-active'],
                    exitDone: styles['field-exit-done'],
                }}
                addEndListener={handleEndListener}
                onEntered={handleEntered}
                onExited={handleExited}>
                <TextField
                    className={styles['field']}
                    ref={searchFieldRef}
                    InputProps={{ inputRef: inputRef }}
                    variant='standard'
                    onChange={handleChange}
                    onBlur={handleSearchBlur}
                    autoComplete='off'
                    classes={classes?.field}
                />
            </CSSTransition>
            <Tooltip title={t('ListSearch.Search')}>
                <IconButton id='searchIconButton' onClick={handleClick} classes={classes?.icon} >
                    <Search />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export interface ListSearchProps {
    /**
     * Will be called if there is a search value set.
     */
    onFilter: (query: string) => void;
    /**
     * The search will wait this interval before searching after the user stopped typing.
     */
    searchInterval?: number;
    classes?: Partial<{ root: string, icon: Partial<IconButtonClasses> & Partial<ClassNameMap<never>>, field: Partial<TextFieldClasses> }>
}
