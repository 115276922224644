import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Checkbox, IconButton, ListItem, Menu, MenuItem, Stack, Tooltip } from '@mui/material'
import editTeamRoleAtom from 'atoms/editTeamRoleAtom'
import selectedTeamRoleAtom from 'atoms/selectedTeamRoleAtom'
import teamRoleColWidthAtom from 'atoms/teamRoleColWidthAtom'
import teamRoleNameWidthAtom from 'atoms/teamRoleNameWidthAtom'
import DoneButton from 'components/Shared/DoneButton'
import { FormInputText } from 'components/Shared/FormComponents/FormInputText'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import theme from 'theme'
import { ITeamRole } from 'types/teamRoles'
import styles from '../../styles/TeamRole.module.css'

const naColor = theme.palette.action.disabled;
const activeColor = theme.palette.primary.main;
const editNameSx = {
    marginLeft: '8px',
    textOverflow: 'ellipsis',
    '& .MuiInput-root': {
        width: 'auto',
        fontSize: 'clamp(1rem, -0.875rem + 4vw,  1.2rem)',
        color: 'rgba(0,0,0,0.60)',
        fontWeight: '500'
    },
    '& .MuiTypography-root': {
        width: 'auto',
        fontSize: 'clamp(1rem, -0.875rem + 4vw,  1.2rem)',
        color: 'rgba(0,0,0,0.60)',
        fontWeight: '500'
    }
};

const TeamRole: React.FC<ITeamRole> =
    ({ teamID, roleId, roleName, teamAdmin, lastAdmin, documents, issues, truckLoading, toggleTeamAdmin, toggleRolePermission, deleteRolePermission, updateRoleName, index }) => {
        let nameWidth = useRecoilValue(teamRoleNameWidthAtom)
        let colWidth = useRecoilValue(teamRoleColWidthAtom)
        const { t } = useTranslation('common')
        const [selectedTeamRole, setSelectedTeamRole] = useRecoilState(selectedTeamRoleAtom)
        const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' })
        const [editMode, setEditMode] = useRecoilState(editTeamRoleAtom)
        const [newRoleName, setNewRoleName] = useState(roleName)
        const [isMouseOver, setIsMouseOver] = useState(false)
        const { handleSubmit, control, setValue, reset, getValues, } = useForm<{ teamRoleName: string }>({
            mode: "onChange"
        })
        const [anchorEl, setAnchorEl] = useState(null)
        const open = Boolean(anchorEl)
        const options = [
            t('TeamRole.DeleteRole'),
        ];
        const resourcePermission = teamAdmin ? naColor : activeColor;

        useEffect(() => setValue('teamRoleName', roleName), [setValue, roleName]);

        const onEditRoleNameSubmit = (data, e) => {
            setEditMode(false)
            updateRoleName(teamID, roleId, roleName, data.teamRoleName)
            setNewRoleName(data.teamRoleName)
        }

        const done = () => {
            const teamRoleName = getValues("teamRoleName")
            if (!teamRoleName) {
                return;
            }
            onEditRoleNameSubmit({ teamRoleName: teamRoleName }, null)
        }

        const onInvalidEditRoleNameSubmit = (data, e) => {
            console.error(data, e);
        }

        const handleBlur = (e) => {
            reset({ teamRoleName: newRoleName });
            setEditMode(false);
        }

        const renameRole = (e: any) => {
            e?.stopPropagation()
            setEditMode(!editMode)
        }

        const setSelected = (e: any, teamID: string, roleName: string, roleId: string, index: number) => {
            e?.stopPropagation()
            if (selectedTeamRole?.index === index) {
                return
            }
            setEditMode(false)
            setSelectedTeamRole({ teamID, role: roleName, roleId, index })
        }

        const handleTeamAdminChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            toggleTeamAdmin(teamID, roleId, checked, event, roleName)
        }

        const handleRoleMenuOpen = (event, teamID, roleName, roleId, index) => {
            setSelected(event, teamID, roleName, roleId, index)
            event?.stopPropagation()
            setAnchorEl(event.currentTarget)
        };

        const handleDeleteRole = (event) => {
            event?.stopPropagation()
            setAnchorEl(null)
            deleteRolePermission(teamID, roleId, roleName);
        };

        const handleDeleteRoleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div onDoubleClick={renameRole} className={`${selectedTeamRole?.index === index && selectedTeamRole?.teamID === teamID ? 'theme-selected-item' : ''}`} >
                <ListItem disableRipple className={`${selectedTeamRole?.index === index && selectedTeamRole?.teamID === teamID ? 'theme-selected-item' : ''}`}
                    onClick={(e) => setSelected(e, teamID, roleName, roleId, index)}
                    onMouseOver={(e) => setIsMouseOver(true)}
                    onMouseLeave={(e) => setIsMouseOver(false)}
                    style={{ padding: 0, display: 'flex', flexDirection: "row", justifyContent: "space-between", alignContent: "center" }}
                    button>
                    {editMode && <form onSubmit={handleSubmit(onEditRoleNameSubmit, onInvalidEditRoleNameSubmit)} className={styles.form}>
                        <div style={{ ...editNameSx }}>
                            {selectedTeamRole?.index === index && selectedTeamRole?.teamID === teamID
                                ? <Stack direction={'row'} justifyContent='flex-start' alignItems={'center'}>
                                    <FormInputText name={'teamRoleName'} variant='standard' control={control} onBlur={handleBlur} onFocus={event => event.target.select()} autoFocus />
                                    <DoneButton done={done} />
                                </Stack>
                                : <span style={{ width: nameWidth, color: 'rgba(0,0,0,0.60)', fontWeight: 500, paddingLeft: '12px' }}>{newRoleName}</span>
                            }
                        </div>
                    </form>}
                    {!editMode && <span style={{ width: nameWidth, color: 'rgba(0,0,0,0.60)', fontWeight: 500, paddingLeft: '20px' }}>{roleName}</span>}
                    <Stack direction="row" justifyContent="flex-start" alignContent="center">
                        <div style={{ width: colWidth }}>
                            {teamAdmin && lastAdmin ? <Tooltip title={t("InfoMessage.OneTeamAdminRequired")}><div><Checkbox sx={{ color: 'rgba(0,0,0,0.60)', '&.Mui-checked': { color: 'rgba(0,0,0,0.27)' } }} disabled onChange={handleTeamAdminChange} defaultChecked={teamAdmin} /></div></Tooltip>
                                : <Tooltip title={teamAdmin ? t('TeamRole.TeamAdmin')?.toString() : t('TeamRole.NoTeamAdmin')?.toString()}><div><Checkbox onChange={handleTeamAdminChange} defaultChecked={teamAdmin} /></div></Tooltip>}
                        </div>
                        <div style={{ width: `${colWidth}px` }}>
                            {(teamAdmin || documents?.canEdit) &&
                                <Tooltip title={t('TeamRole.CanEdit')?.toString()}>
                                    <IconButton disabled={teamAdmin} onClick={() => toggleRolePermission(teamID, roleId, roleName, { canEdit: documents.canEdit }, 'document')}>
                                        <EditIcon style={{ fill: resourcePermission }} />
                                    </IconButton>
                                </Tooltip>}
                            {(!teamAdmin && documents?.canRead) && <Tooltip title={t('TeamRole.CanRead')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { canRead: documents.canRead }, 'document')}>
                                    <VisibilityIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                            {(!teamAdmin && documents?.notDefined) && <Tooltip title={t('TeamRole.NoAccess')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { notDefined: documents.notDefined }, 'document')}>
                                    <CloseIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                        </div>
                        <div style={{ width: `${colWidth}px` }}>
                            {(teamAdmin || issues?.canEdit) && <Tooltip title={t('TeamRole.CanEdit')?.toString()}>
                                <IconButton disabled={teamAdmin} onClick={() => toggleRolePermission(teamID, roleId, roleName, { canEdit: issues.canEdit }, 'issue')}>
                                    <EditIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                            {(!teamAdmin && issues?.canRead) && <Tooltip title={t('TeamRole.CanRead')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { canRead: issues.canRead }, 'issue')}>
                                    <VisibilityIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                            {(!teamAdmin && issues?.notDefined) && <Tooltip title={t('TeamRole.NoAccess')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { notDefined: issues.notDefined }, 'issue')}>
                                    <CloseIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                        </div>
                        <div style={{ width: `${colWidth}px` }}>
                            {(teamAdmin || truckLoading?.canEdit) && <Tooltip title={t('TeamRole.CanEdit')?.toString()}>
                                <IconButton disabled={teamAdmin} onClick={() => toggleRolePermission(teamID, roleId, roleName, { canEdit: truckLoading.canEdit }, 'hsbstacking')}>
                                    <EditIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                            {(!teamAdmin && truckLoading?.canRead) && <Tooltip title={t('TeamRole.CanRead')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { canRead: truckLoading.canRead }, 'hsbstacking')}>
                                    <VisibilityIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                            {(!teamAdmin && truckLoading?.notDefined) && <Tooltip title={t('TeamRole.NoAccess')?.toString()}>
                                <IconButton onClick={() => toggleRolePermission(teamID, roleId, roleName, { notDefined: truckLoading.notDefined }, 'hsbstacking')}>
                                    <CloseIcon style={{ fill: resourcePermission }} />
                                </IconButton></Tooltip>}
                        </div>
                        <div style={{ width: `${colWidth}px` }}>
                            <div style={{ visibility: isMouseOver ? 'visible' : 'collapse' }}>
                                <IconButton
                                    aria-label="more"
                                    id="delete-role-button"
                                    aria-controls={open ? 'delete-role-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleRoleMenuOpen(e, teamID, roleName, roleId, index)}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                                <Menu id="delete-role-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'delete-role-button',
                                    }}
                                    anchorEl={anchorEl} open={open} onClose={handleDeleteRoleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: '20ch',
                                        },
                                    }}>
                                    {options.map((option) => (
                                        <MenuItem key={option} onClick={handleDeleteRole}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        </div>
                    </Stack>
                </ListItem>
                <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
            </div>
        )
    }

export default TeamRole;