import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import restAPI from 'services/rest-api';
import { Permission } from 'types/permission';

const usePermissions = (requiredPermissions: Permission[], projectID: string) => {

    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const projectQuery = useQuery(['projectInfo', projectID], () => getProjectInfo(), { enabled: false });
    const teamUserPermissionsQuery = useQuery(['teamUserPermissions',
        projectQuery?.data?.teamID],
        () => getTeamUserPermissions(projectQuery?.data?.teamID),
        { enabled: false }
    );

    const getProjectInfo = useCallback(async (): Promise<any> => {
        if (!projectID) {
            return null;
        }
        const { data } = await restAPI.getProjectInfo(projectID);
        return data;
    }, [projectID]);

    useEffect(() => {
        if (!projectQuery.isFetched) {
            projectQuery.refetch();
            return;
        }
        teamUserPermissionsQuery.refetch();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectQuery.isFetched]);

    useEffect(() => {
        if (!projectQuery.isFetched || !teamUserPermissionsQuery.isFetched) {
            setHasAccess(false);
            setLoading(true);
            return;
        }
        const teamUserPermissions: Permission[] = teamUserPermissionsQuery.data;
        //  Check if the caling user has the required permission/s
        setHasAccess(requiredPermissions.some((permission) => teamUserPermissions?.includes(permission)));
        setLoading(false);
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamUserPermissionsQuery.data]);

    return { loading, hasAccess };
};

const getTeamUserPermissions = async (teamId: string): Promise<Permission[]> => {
    if (!teamId) {
        return null;
    }
    const { data } = await restAPI.getTeamUserPermissions(teamId);
    return data;
};

export default usePermissions;
