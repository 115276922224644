import { Box, FormControl, Grid, OutlinedInput, Typography, } from "@mui/material";
import HsbShareLogo from "components/Shared/HsbShareLogo";
import RoundedButton from "components/Shared/RoundedButton";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import constants from "../../services/constants";
import respAPI from "../../services/rest-api";
import styles from "../../styles/ForgotPassword.module.css";
import HalfFooter from "../Shared/Footer/HalfFooter";

export default function ForgotPassword() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        let email = values?.email?.trim();
        const response = await respAPI.requestForgetPassword(
          email
        );
        if (response?.data?.message === "Reset password email sent") {
          navigate(`/email-sent?u=${email}&c=reset password`);
        }
      } catch (error) { }
    },
  });

  return (
    <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
      <Box>
        <HsbShareLogo width={222} />
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Typography>
              <Link to="/login"> {t("forgotpassword.back")} </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" {...constants.pageHeading}>
              {t("forgotpassword.title")}
            </Typography>
            <Typography {...constants.pageText} style={{ paddingBottom: 15 }}>
              {t("forgotpassword.undertitle")}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="caption" gutterBottom>
                {t("forgotpassword.email")}
              </Typography>
              <OutlinedInput size="small" required type="email" id="email" onChange={formik.handleChange} value={formik.values.email} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.section}>
              <RoundedButton type="submit" variant="contained">
                {t("forgotpassword.button")}
              </RoundedButton>
            </div>
          </Grid>
        </form>
      </Box>
      <Box>
        <HalfFooter />
      </Box>
    </Grid>
  );
}

