import { useCallback } from 'react';
import { useImmer } from "use-immer";

function useArray<T = any>(initial: Array<T>) {
    const [items, setItems] = useImmer(initial);
    return {
        items,
        setItems,
        add: useCallback(itemToAdd => setItems(draft => {
            draft.push(itemToAdd)
        }), [setItems]),
        update: useCallback((propertyNameId: string, id: string, editPropertyName: string, editPropertyValue: any) =>
            setItems((draft) => {
                const found = draft.find(item => item[propertyNameId] === id)
                if (found) {
                    found[editPropertyName] = editPropertyValue
                }
            }), [setItems]),
        remove: useCallback((propertyNameId: string, id: string) =>
            setItems((draft) => {
                const index = draft.findIndex(item => item[propertyNameId] === id)
                if (index !== -1) {
                    draft.splice(index, 1)
                }
            }), [setItems]),
    };
};

export default useArray;    