import React from 'react'
import { useState } from 'react'
import { alpha, Box, IconButton, Menu, MenuItem, Stack, styled, Tooltip } from '@mui/material'
import ActivitySelect from './ActivitySelect'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import Check from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'

const headerStyle = {
    background: '#ffffff',
    p: 1,
    boxShadow: '0 2px 2px -2px rgba(0, 0, 0, 0.4)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',

}

const menuHeader = {
    height: '40px',
    background: '#EEEEEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '5px'
}

const menuHeaderText = {
    color: '#797671',
}

const StyledMenu = styled((props: any) => (
    <Menu
        open={props.open}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface IFilter {
    minor: boolean,
    normal: boolean,
    major: boolean,
    critical: boolean,
    onHold: boolean,
    active: boolean,
    activeDue: boolean
}

export const ActivityItemHeader: React.FC<{ activity: string }> = ({ activity }) => {
    const [filter, setFilter] = useState<IFilter>({
        minor: true, normal: true, major: false, critical: false, onHold: false,
        active: false, activeDue: false
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation('common')
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    type filterPropertyNames = 'minor' | 'normal' | 'major' | 'critical' | 'onHold' | 'active' | 'activeDue'
    const handleSelect = (filterPropertyName: filterPropertyNames) => {
        if (typeof filterPropertyName !== 'string') {
            return;
        }
        setFilter({ ...filter, [filterPropertyName]: !filter[filterPropertyName] })
    }

    return (
        <Box sx={headerStyle}>
            <Stack direction='row' justifyContent='flex-start' alignItems='center' >
                <ActivitySelect activity={activity}></ActivitySelect>
                <div style={{ flexGrow: 4 }}></div>
                <Tooltip title={t('ActivityMenu.Search')}>
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('ActivityMenu.Filter')}>
                    <IconButton>
                        <FilterListIcon aria-controls="activity-filter-menu" onMouseDown={handleClick} />
                    </IconButton>
                </Tooltip>
                <StyledMenu id="activity-filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleSelect}
                    onClose={() => { setAnchorEl(null) }}
                >
                    <div>
                        <div style={menuHeader}>
                            <span style={menuHeaderText}>
                                {t('ActivityMenu.Priority')}
                            </span>
                        </div>
                        {/* <Divider sx={{ my: 0.5 }} /> */}
                        <MenuItem onClick={() => handleSelect('minor')} disableRipple>
                            {filter.minor ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.Minor')}
                        </MenuItem>
                        <MenuItem onClick={() => handleSelect('normal')} disableRipple>
                            {filter.normal ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.Normal')}
                        </MenuItem>
                        <MenuItem onClick={() => handleSelect('major')} disableRipple>
                            {filter.major ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.Major')}
                        </MenuItem>
                        <MenuItem onClick={() => handleSelect('critical')} disableRipple>
                            {filter.critical ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.Critical')}
                        </MenuItem>
                        <MenuItem onClick={() => handleSelect('onHold')} disableRipple>
                            {filter.onHold ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.OnHold')}
                        </MenuItem>
                        {/* <Divider sx={{ my: 0.5 }} /> */}
                        <div style={menuHeader}>
                            <span style={menuHeaderText}>
                                {t('ActivityMenu.State')}
                            </span>
                        </div>
                        {/* <Divider sx={{ my: 0.5 }} /> */}
                        <MenuItem onClick={() => handleSelect('active')} disableRipple>
                            {filter.active ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.Active')}
                        </MenuItem>
                        <MenuItem onClick={() => handleSelect('activeDue')} disableRipple>
                            {filter.activeDue ? <Check /> : <Check sx={{ visibility: 'hidden' }} />}
                            {t('ActivityMenu.ActiveDue')}
                        </MenuItem>
                    </div>
                </StyledMenu>
            </Stack>
        </Box>
    )
}
