import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, FormHelperText, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputSelectProps } from "../FormInputProps";
import styles from './FormInputSelect.module.scss';

export const FormInputSelect: React.FC<FormInputSelectProps & any> = ({
  name,
  control,
  label,
  variant,
  defaultValue,
  children,
  endButton,
  classes,
  search,
  rules,
  helpertext,
  onChange: propsOnChange,
  placeholder,
  ...props
}) => {
  const [searchText, setSearchText] = useState("");

  return (
    <Box className={`${classes?.container}`} style={{ display: 'block' }}>
      {label &&
        <InputLabel>{label}</InputLabel>}
      <FormControl size={"small"} variant={variant ?? "outlined"} onChange={propsOnChange} sx={{ width: '100%' }}>
        <Controller
          defaultValue={defaultValue}
          control={control}
          rules={rules}
          name={name}
          render={({ field: { value, ref, onChange, onBlur },
            fieldState: { error }
          }) =>
          (<React.Fragment >
            <Select
              onBlur={onBlur}
              disabled={!(children && React.Children.count(children) > 0)}
              value={(value && (children && React.Children.count(children) > 0)) ? value : ''}
              displayEmpty
              inputRef={ref}
              endAdornment={endButton}
              MenuProps={{ classes: { paper: styles['menu-container'] }, disableAutoFocusItem: true, variant: 'menu', MenuListProps: { variant: 'menu', autoFocus: false, autoFocusItem: false } }}
              onChange={(...data) => { onChange(...data); propsOnChange && propsOnChange(...data); }}
              onClose={(e) => { e.stopPropagation(); setSearchText(""); }}
              {...props}>
              {
                search &&
                <ListSubheader className={styles['menu-search-field']}>
                  <TextField
                    size="small"
                    autoFocus
                    placeholder="Search items"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => { setSearchText(e.target.value); e.stopPropagation(); }}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
              }
              {
                placeholder &&
                <MenuItem key='placeholder' value='' style={{ display: 'none', color: 'rgba(0,0,0,0.27)' }}>
                  {placeholder}
                </MenuItem>
              }
              {
                children?.map(child => {
                  if (child?.props?.children?.toString().toLowerCase()?.indexOf(searchText.toLowerCase()) > -1) {
                    return child;
                  }
                  return <MenuItem sx={{ display: 'none' }} value={child.props?.value} key={child.key}>{child.props?.children}</MenuItem>
                })
              }
            </Select>
            <FormHelperText>{error ? (helpertext || error.message) : null}</FormHelperText>
          </React.Fragment>)
          }
        />
      </FormControl>
    </Box >
  );
};