import { HsbEntity } from "hsbshareviewer";

export class NodeData {
    private _selected: boolean = false;
    public get selected(): boolean { return this._selected; };
    public set selected(v: boolean) { this._selected = v; }
    public get hasGeometry(): boolean { return (!!this.entity?.object || this.children.some((c) => c.hasGeometry)) }
    public children: NodeData[];
    public parent: NodeData;
    public commpackage: any;
    public dbId: number;
    public entity: HsbEntity;
    public entities: HsbEntity[];
    public groupsite: string;
    public hsbmodel: any;
    public metadata: any;
    public modelId: string;
    public name: string;
    public nodeModel: any;
    public pathid: string;
    private _level: number;
    public get level(): number {
        return this._level;
    }

    constructor(node: any, level?: number) {
        let self = this;
        this._level = level || 0;
        Object.assign(this, node);
        this.children = this.children?.map((child) => { let out = new NodeData(child, self.level + 1); out.parent = self; return out; });
    }

    public getAllEntities(entities?: any[]) {
        if (!entities)
            entities = [];
        if (this.entity)
            entities.push(this.entity);
        this.children?.forEach((child) => child.getAllEntities(entities));
        return entities;
    }

    public get visible(): boolean {
        let obj = this.entity?.object;
        if (this.entity?.instanceId !== undefined && obj) {
            let handle = this.entity.handle;
            let index = obj.userData.handle.findIndex((h) => h === handle);
            return (index > 0 && index < ((obj as THREE.InstancedMesh).count - 1));
        }
        else if (obj) {
            return obj.visible;
        }
        if (!obj)
            return this.children?.some((c) => c.visible);
        return this.entity?.object?.visible || this.children?.some((c) => c.visible);
    }
}