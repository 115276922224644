import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 25,
    textTransform: 'none',
    padding: '5px 40px',
}))

const RoundedButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    return (
        <StyledButton {...props} >
            {props.children}
        </StyledButton>
    )
}

export default RoundedButton