import { Box } from '@mui/system';
import useSession from 'hooks/useSession';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export default function PrivateRoute({ children }: any) {
    const { isAuth, isGuest, logout } = useSession();
    const [privateAccess, setPrivateAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth()) {
            logout();
            return;
        }

        if (isGuest()) {
            navigate('/login')
            return;
        }

        setPrivateAccess(true);
    }, [isAuth, isGuest, logout, navigate])

    //not logged in redirect.
    return (privateAccess ? children : <Box />)
}
