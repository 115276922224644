import { Box, Checkbox, InputLabel } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import styles from './FormInput.module.css';
import { FormInputCheckboxProps } from "./FormInputProps";

export const FormInputCheckbox: React.FC<FormInputCheckboxProps> = ({
  name,
  control,
  label,
  classes,
  onChange: onChangeProps
}) => {
  return (
    <Box className={`${classes?.container}`} style={{ display: 'flex', alignItems: 'center' }}>
      {label &&
        <InputLabel className={styles.label} style={{ display: "inline" }}>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        render={({
          field: { value, onChange }
        }) => {
          return (
            <Checkbox checked={value} onChange={(e: any) => { onChange(e); onChangeProps(e) }} />
          )
        }}
      />
    </Box>
  );
};