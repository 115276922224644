import { Box, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import config from 'services/config';
import LanguageBar from '../LanguageBar';
import styles from './Footer.module.scss';

export default function HalfFooter() {
    const { t } = useTranslation('common');

    return (
        <Grid container direction="column" justifyContent="flex-end" alignItems="center" item xs={12}>
            <Typography style={{ color: 'rgb(139, 139, 139, 0.6)', paddingBottom: 6, fontSize: 14, fontWeight: 400 }}>
                <Trans t={t} key={'footer.copyright'} values={{ year: new Date().getFullYear() }}>
                    {'© hsbcad {{year}} All Rights Reserved.'}
                </Trans>
            </Typography>
            <LanguageBar />
            <Box className={styles['version-info']}>{config.version}</Box>
        </Grid>
    );
}
