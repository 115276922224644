import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton, List, Paper, Stack, Tooltip, Typography } from '@mui/material'
import amITeamAdminAtom from 'atoms/amITeamAdmin'
import ListSearch from 'components/Shared/List/Search/ListSearch'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useRecoilValue } from 'recoil'
import styles from 'styles/TeamMemberList.module.scss'

const TeamMemberList: React.FC<{ children: Array<any>, addTeamMember: () => void, onFilter: (query: string) => void }> = ({ children, addTeamMember, onFilter }) => {
    const { t } = useTranslation('common');
    const amITeamAdmin = useRecoilValue(amITeamAdminAtom);
    return (
        <Box className={styles.teamMemberList}>
            <Stack direction='row' justifyContent='flex-start' flexWrap='nowrap' alignItems='center' minHeight={'40px'}>
                <Typography variant="h5"  >{t('TeamMemberList.Title')}</Typography>
                <Box className={styles.actions}>
                    <ListSearch onFilter={onFilter} />
                    {amITeamAdmin &&
                        <Tooltip title={"Add Member"}>
                            <IconButton onClick={() => addTeamMember()}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            </Stack>
            <Paper elevation={2} classes={{ root: styles.paper }}>
                <PerfectScrollbar className={styles.scrollBar}
                    options={{ suppressScrollX: true }}>
                    <List classes={{ root: styles.list }}>
                        {children?.map((listItem: any) => listItem)}
                    </List>
                </PerfectScrollbar>
            </Paper>
        </Box>
    )
}
export default TeamMemberList