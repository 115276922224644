import { Box, Grid, Typography } from "@mui/material";
import HsbShareLogo from "components/Shared/HsbShareLogo";
import RoundedButton from "components/Shared/RoundedButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import constants from "../../services/constants";
import styles from "../../styles/MailSent.module.css";
import HalfFooter from "../Shared/Footer/HalfFooter";

export default function MailSent() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  let user = urlParams.get("u");
  let context = urlParams.get("c");

  const navigateHome = () => {
    navigate("/login");
  };

  return (
    <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
      <Box>
        <HsbShareLogo width={222} />
      </Box>
      <Box>
        <Grid item xs={12}>
          <Typography variant="h1" className={styles['page-heading']} {...constants.pageHeading}>
            {t("checkyourmail.title")}
          </Typography>
          <Typography {...constants.pageText} style={{ paddingBottom: 10 }}>
            {t("checkyourmail.undertitle", { user: user, context: context })}
          </Typography>
          <Typography {...constants.pageText}>
            {t("checkyourmail.undertitle2")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.section}>
            <RoundedButton type="submit" variant="contained" onClick={() => navigateHome()}>
              {t("checkyourmail.button")}
            </RoundedButton>
          </div>
        </Grid>
      </Box>
      <Box>
        <HalfFooter />
      </Box>
    </Grid>
  );
}
