import { Box, Typography } from '@mui/material';
import image from 'assets/FourOFour.png';
import LanguageBar from 'components/Shared/LanguageBar';
import RoundedButton from 'components/Shared/RoundedButton';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styles from '../styles/NotFoundPage.module.scss';

export default function NotFoundPage() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <Box className={styles['not-found-container']}>
            <Box className={styles['not-found-body']} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
                <Box className={styles['not-found-share-logo']}>
                    <img alt="hsbShare logo" src="/img/hsbShareLogo.svg" />
                </Box>
                <Box className={styles['not-found-form']}>
                    <Box className={styles['not-found-graphic']}>
                        <img alt="hsbShare logo" src="/img/404small.svg" height={'180px'} />
                    </Box>
                    <Typography variant="h4">{t('notfoundpage.title')}</Typography>
                    <Typography>{t('notfoundpage.undertitle')}</Typography>

                    <Box className={styles['not-found-form-actions']}>
                        <RoundedButton type="submit" variant="contained" onClick={() => navigateHome()}>
                            {t('notfoundpage.button')}
                        </RoundedButton>
                        <RoundedButton type="submit" variant="contained" onClick={() => navigate(-1)}>
                            {t('notfoundpage.back')}
                        </RoundedButton>
                    </Box>
                </Box>
                <Box className={styles['not-found-footer']}>
                    <Typography style={{ color: 'rgb(139, 139, 139, 0.6)', paddingBottom: 6, fontSize: 14, fontWeight: 400 }}>
                        <Trans t={t} key={'footer.copyright'} year={new Date().getFullYear()}></Trans>
                    </Typography>
                    <LanguageBar />
                </Box>
            </Box>

            <Box className={styles['not-found-image-container']}>
                <img className={styles['not-found-image']} alt="404" src={image} />
            </Box>
        </Box>
    );
}
