import { Close } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Link, TextField, Typography } from '@mui/material';
import UserAvatar from 'components/Shared/AvatarObject';
import DialogBox from 'components/Shared/DialogBox';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import useSession from 'hooks/useSession';
import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styles from 'styles/UserSettings.module.scss';
import restAPI from '../../services/rest-api';
import { IShareSession } from '../../types/IShareSession';
import { FormInputAvatar } from '../Shared/FormComponents/FormInputAvatar';
import { FormInputPhone } from '../Shared/FormComponents/FormInputPhone';
import { FormInputSelect } from '../Shared/FormComponents/FormInputSelect/FormInputSelect';
import { FormInputText } from '../Shared/FormComponents/FormInputText';
import SnackBarMessage, { ISnackBarData } from '../Shared/snackBar/SnackBarMessage';
import { UserSettingsValues } from './UserSettings';

export interface GeneralSettings {
    name: string;
    email: string;
    avatar: UserAvatar;
    phone: string;
    team: any;
    teams: any;
    registered: string;
}

const TeamOption = (team: any) => {
    return <FormInputSelectMenuItem key={team.teamID} value={team.teamID}>{team.name}</FormInputSelectMenuItem>;
}

export default function GeneralSettingsPanel(props: GeneralSettingsPanelProps) {
    const { t } = useTranslation('common');
    const { logout, getSession } = useSession()
    const navigate = useNavigate();
    const { control, setValue, getValues } = props.useForm;
    const { values } = props;
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' });
    const getSessionCallback = useCallback(getSession, [getSession]);
    const [registered, setRegistered] = useState<Date>(new Date())
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);
    const [showForgotDialog, setShowForgotDialog] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const currentSession: IShareSession = getSessionCallback()
        if (!currentSession) {
            return;
        }

        if (!isMounted)
            return;
        if (values) {
            setRegistered(new Date(values.registered) ?? new Date())
        }

        return () => {
            isMounted = false
            setShowDeleteDialog(false);
            setShowForgotDialog(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    const deleteAccount = () => {
        console.warn("UserSettings::deleteAccount: Not implemented")
        /**
         * @todo API call to delete account
         */
        setShowDeleteDialog(true);
    }

    const confirmDeleteAccount = (event: any, confirm: boolean) => {
        event?.preventDefault();
        if (confirm) {
            restAPI.deleteAccount();
        }
        setShowDeleteDialog(false);
    }

    const forgotPassword = () => {
        console.warn("UserSettings::forgotPassword: Not implemented")
        setShowForgotDialog(true);
    }

    const onConfirmChange = (event) => {
        if (event.target.value.match('DELETE')) {
            setDeleteConfirmed(true);
        } else {
            setDeleteConfirmed(false);
        }
    }

    const confirmForgotPassword = (event: any, confirm: boolean) => {
        event?.preventDefault();
        if (confirm) {
            logout();
            navigate("/forgot-password");

        }
        setShowForgotDialog(false);
    }

    const handleDeleteClose = () => {
        setShowDeleteDialog(false);
    }

    const handleForgetClose = () => {
        setShowForgotDialog(false);
    }

    return (
        <div>
            <div className={styles.inputGrid} >
                {/* Avatar */}
                <FormInputAvatar name="generalSettings.avatar" control={control} setValue={setValue} getValues={getValues} label={t('GeneralSettings.Avatar')} size={"80px"} />
                {/* Name */}
                <FormInputText name="generalSettings.name" control={control} InputProps={{ type: "text" }} errortext={t('GeneralSettings.ValidName')} rules={{ required: (t('GeneralSettings.UsernameRequired') as string) }} label={t('GeneralSettings.Username')} />
                {/* Email */}
                <FormInputText name="generalSettings.email" control={control} InputProps={{ type: "email" }}
                    errortext={t('GeneralSettings.ValidEmail')}
                    rules={{
                        required: (t('GeneralSettings.EmailRequired') as string),
                        pattern: /\S+@\S+\.\S+/
                    }}
                    label={t('GeneralSettings.Email')} />
                {/* Phone */}
                <FormInputPhone name="generalSettings.phone" control={control} helpertext={t('GeneralSettings.ValidPhone')} label={t('GeneralSettings.Phone')} />
                {/* Team */}
                <FormInputSelect search name="generalSettings.team" control={control} variant="outlined" label={t('GeneralSettings.Team')}>
                    {values?.teams?.map((team) => TeamOption(team))}
                </FormInputSelect>
                {/* Registered */}
                <Typography>
                    <span className={styles.label}>{t('GeneralSettings.RegisteredOn')}</span> {registered?.toLocaleDateString()}
                </Typography>
            </div>
            <p />
            <Link underline='hover' onClick={() => forgotPassword()} className={styles.button}>{t('GeneralSettings.ResetPassword')}</Link>
            <p />
            <Link underline='hover' color="error" onClick={() => deleteAccount()} className={styles.button}>{t('GeneralSettings.DeleteAccount')}</Link>
            <p />
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
            <DialogBox aria-labelledby="delete-dialog" open={showDeleteDialog} onClose={handleDeleteClose}>
                <DialogTitle variant={'error'}>
                    {t('GeneralSettings.DeleteAccount')}
                    <IconButton onClick={handleDeleteClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {t('GeneralSettings.DeleteWarning', { deleteVar: 'DELETE' })}
                    <TextField sx={{ display: "block", marginTop: '10px' }} onChange={onConfirmChange} />
                </DialogContent>
                <DialogActions>
                    <Button type='submit' variant="contained" color="error" onClick={(event) => { confirmDeleteAccount(event, true) }} disabled={!deleteConfirmed}>{t('GeneralSettings.DeleteAccount')}</Button>
                </DialogActions>
            </DialogBox>
            <DialogBox aria-labelledby="forget-dialog" open={showForgotDialog} onClose={handleForgetClose}>
                <DialogTitle variant={'warning'}>
                    {t('GeneralSettings.ResetPassword')}
                    <IconButton onClick={handleForgetClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {t('GeneralSettings.ResetWarning')}
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" onClick={(event) => { confirmForgotPassword(event, true) }}>{t('GeneralSettings.GoToRecovery')}</Button>
                </DialogActions>
            </DialogBox>
        </div>
    )
}

export interface GeneralSettingsPanelProps {
    formId?: string;
    useForm: UseFormReturn<UserSettingsValues>;
    values: GeneralSettings;
}
