import { Dispatch, SetStateAction, createRef, useState } from 'react';
import { Box, List, Typography } from '@mui/material';
import Tab from 'components/Shared/Tab';
import { useTranslation } from 'react-i18next';
import styles from './DocumentsPanel.module.scss';
import FolderElement from './FolderElement';
import FileElement from './FileElement';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import Requires from 'components/Shared/Permissions/Requires';
import usePermissions from 'hooks/usePermissions';
import restAPI from '../../../services/rest-api';

export default function DocumentsPanel({ open, projectId, onClose, folders, setFolders, copiedFile, setCopiedFile, handleAddFile }: DocumentsPanelProps) {
    const { t } = useTranslation('common');
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [search, setSearch] = useState(null);
    const folderListRef = createRef<HTMLUListElement>();
    const fileListRef = createRef<HTMLUListElement>();
    const { hasAccess} = usePermissions(['document.edit'], projectId);

    const handleSearch = (query: string) => {
        setSearch(query?.length > 0 ? query.toLowerCase() : null);
    };

    const handleFolderSelection = (e: any, folderId: string) => {
        setSelectedFolder(folders.find((folder) => folder._id === folderId));
    };

    const handleBackButton = () => {
        setSelectedFolder(null);
    }

    const handleAddFolder = (projectId: string) => {
        restAPI.addFolder(projectId, 'New Folder').then((result) => {
            setFolders([...folders, ...[result]]);
        });
    };

    const handleRemoveFolder = (folderId: string) => {
        restAPI.deleteFolder(folderId).then((result) => {
            setFolders(folders.filter((folder) => folder._id !== result._id));
            if (copiedFile?.folderId === folderId) {
                setCopiedFile(null);
            }
        });
    };

    const handleDuplicateFolder = (folderId: string) => {
        restAPI.duplicateFolder(folderId).then((result) => {
            setFolders([...folders, ...[result]]);
        });
    };

    const handleFolderRename = (folderId: string, name: string) => {
        restAPI.renameFolder(folderId, name).then((result) => {
            const foldersCopy = Array.from(folders);
            Object.assign(
                foldersCopy.find((folder) => folder._id === result._id),
                result
            );
            setFolders(foldersCopy);
        });
    };

    const handlePasteFile = (selectedFolder: any) => {
        if (selectedFolder && copiedFile) {
            if (copiedFile.isCut) {
                restAPI.cutFile(copiedFile.file.path, copiedFile.folderId, selectedFolder._id).then((result) => {
                    const foldersCopy = Array.from(folders);
                    Object.assign(
                        foldersCopy.find((folder) => folder._id === result.source._id),
                        result.source
                    );
                    Object.assign(
                        foldersCopy.find((folder) => folder._id === result.target._id),
                        result.target
                    );
                    setFolders(foldersCopy);
                });
            } else {
                restAPI.copyFile(copiedFile.file.path, copiedFile.folderId, selectedFolder._id).then((result) => {
                    const foldersCopy = Array.from(folders);
                    Object.assign(
                        foldersCopy.find((folder) => folder._id === result._id),
                        result
                    );
                    setFolders(foldersCopy);
                });
            }
        }
    };

    const handleRemoveFile = (file: any) => {
        if (selectedFolder && file) {
            restAPI.deleteFile(selectedFolder._id, file.name).then((result) => {
                const foldersCopy = Array.from(folders);
                Object.assign(
                    foldersCopy.find((folder) => folder._id === result._id),
                    result
                );
                setFolders(foldersCopy);
                if (copiedFile?.file._id === file._id) {
                    setCopiedFile(null);
                }
            });
        }
    };

    const handleCopyCutFile = (file: any, isCut: boolean) => {
        setCopiedFile({
            isCut: isCut,
            folderId: selectedFolder._id,
            file: file,
        });
    };

    return (
        <Tab
            open={open}
            direction={'left'}
            onClose={onClose}
            title={t('DocumentsPanel.Documents')}
            onSearch={handleSearch}
            onFileSelectAddition={selectedFolder  && hasAccess && ((e: any) => handleAddFile(e, selectedFolder))}
            classes={{ paper: styles.sidePanel }}>
            <Box className={styles['panel-content']}>
                {!selectedFolder ? (
                    <List ref={folderListRef} classes={{ root: styles.list }}>
                        {folders
                            ?.filter((folder) => !search || folder.properties.name.value.toLowerCase().includes(search))
                            .map((folder) => {
                                return (
                                    <FolderElement
                                        key={folder._id}
                                        folder={folder}
                                        projectId={projectId}
                                        onFolderSelection={handleFolderSelection}
                                        onFolderDelete={handleRemoveFolder}
                                        onFolderDuplication={handleDuplicateFolder}
                                        onFolderRename={handleFolderRename}></FolderElement>
                                );
                            })}
                        <Requires permissions={['document.edit']} projectId={projectId} >
                            <ContextualMenu parent={folderListRef}>
                                <ContextualMenuItem onClick={(e: any) => handleAddFolder(projectId)}>{t('DocumentsPanel.AddFolder')}</ContextualMenuItem>
                            </ContextualMenu>
                        </Requires>
                        {!(folders?.length > 0) && (
                            <Box className={styles['no-content']}>
                                <Typography variant="subtitle1">{t('DocumentsPanel.NoFolders')}</Typography>
                            </Box>
                        )}
                    </List>
                ) : (
                    <List ref={fileListRef} classes={{ root: styles.list }}>
                        <Box className={styles['back-folder-button']} onClick={handleBackButton}>
                            <ArrowLeftIcon />
                            <Typography>{selectedFolder.properties.name.value}</Typography>
                        </Box>
                        {selectedFolder.documents
                            .filter((file) => !search || (file.name || file.path.split("/").pop()).toLowerCase().includes(search))
                            .map((file) => {
                                return (
                                    <FileElement
                                        folder={selectedFolder}
                                        key={file._id}
                                        isMarkedForCut={
                                            copiedFile?.isCut &&
                                            copiedFile.folderId === selectedFolder._id &&
                                            copiedFile.file._id === file._id
                                        }
                                        file={file}
                                        projectId={projectId}
                                        handleCopyCutFile={handleCopyCutFile}
                                        handleRemoveFile={handleRemoveFile}></FileElement>
                                );
                            })}
                        <Requires permissions={['document.edit']} projectId={projectId} >
                            <ContextualMenu parent={fileListRef}>
                                <ContextualMenuItem disabled={!copiedFile} onClick={handlePasteFile}>
                                    {t('DocumentsPanel.Paste')}
                                </ContextualMenuItem>
                            </ContextualMenu>
                        </Requires>
                        {!(selectedFolder.documents?.length > 0) && (
                            <Box className={styles['no-content']}>
                                <Typography variant="subtitle1">{t('DocumentsPanel.NoFiles')}</Typography>
                            </Box>
                        )}
                    </List>
                )}
            </Box>
        </Tab>
    );
}

export interface DocumentsPanelProps {
    open: boolean;
    projectId: string;
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    folders: any[];
    setFolders: Dispatch<SetStateAction<any[]>>;
    copiedFile: any;
    setCopiedFile: Dispatch<SetStateAction<any>>;
    handleAddFile: (e: any, selectedFolder: any) => void;
}
