import pkg from './../../package.json';

const config = {
    production: process.env.REACT_APP_UI_ENVIRONMENT === "production",
    development: process.env.REACT_APP_UI_ENVIRONMENT === "development" || process.env.REACT_APP_UI_ENVIRONMENT === "local",
    local: process.env.UI_ENVIRONMENT === "local",
    server: process.env.REACT_APP_BACKEND_SERVER,
    localServer: 'http://localhost:3000',
    automation: process.env.REACT_APP_AUTOMATION_SERVER,
    getHostName: (server: string) => {
        return server.split('//')[1];
    },
    guestUser: process.env.REACT_APP_GUEST_USER,
    guestPassword: process.env.REACT_APP_GUEST_PASSWORD,
    version: pkg.version
};
export default config;