import usePermissions from "hooks/usePermissions";
import { Permission } from "types/permission";

interface PermissionProps {
    permissions: Permission[];
    projectId: string;
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
    children?: JSX.Element | string;
};

const Requires: React.FunctionComponent<PermissionProps> = ({ permissions, projectId, fallback, loadingComponent, children }) => {
    const { loading, hasAccess } = usePermissions(permissions, projectId);

    if (loading) {
        return <>{loadingComponent}</>;
    }
    if (hasAccess) {
        return <>{children}</>;
    }

    return <>{fallback}</>;
};

export default Requires;