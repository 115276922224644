const constants = {
    authCookie: 'hsbshare.auth',
    pathsAtom: 'tb.iconColors',
    mainWindowMainHeightAtom: 'sn.mainWindowMainHeightAtom',
    selectedTeamAtom: 'tl.selectedTeam',
    selectedTeamMemberAtom: 'tl.selectedTeamMember',
    selectedTeamRoleAtom: 'tl.selectedTeamRole',
    updatedTeamRoleAtom: 'tl.updatedTeamRole',
    updatedIssueAtom: 'is.updatedIssue',
    issueUpdatedAtom: 'is.issueUpdated',
    reloadActiveIssueTimelineAtom: 'is.reloadActiveIssueTimelineAtom',
    addedTeamRoleAtom: 'tl.addedTeamRoleAtom',
    deletedTeamMemberAtom: 'tl.deletedTeamMember',
    deletedTeamRoleAtom: 'tl.deletedTeamRole',
    updatedTeamMemberAtom: 'tl.updatedTeamMember',
    editTeamAtom: 'tl.editSelectedTeam',
    importSetCreatedAtom: 'is:importSetCreatedAtom',
    editTeamRoleAtom: 'tl.editSelectedTeamRole',
    scrollCommentIntoViewAtom: 'is.scrollCommentIntoView',
    teamRoleNameWidthAtom: 'tl.teamRoleNameWidthAtom',
    teamRoleColWidthAtom: 'tl.teamRoleColWidthAtom',
    amITeamAdminAtom: 'tl.amITeamAdmin',
    publicProjectPoliciesAtom: 'pj.publicProjectPoliciesAtom',
    teamRolesDialogOpenAtom: 'tl.teamRolesDialogOpenAtom',
    languageStorage: 'language',
    vSpacing: { sx: { marginBottom: '15px' } },
    pageHeading: {
        sx: {
            fontWeight: 700,
            color: 'rgba(0, 0, 0, 0.62)',
            size: '36px',
        }
    },
    pageText: {
        sx: {
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.60)',
            size: '16px',

        }
    },
    selectedColor: 'rgba(249, 175, 8, 0.08)'
};
export default constants;