import React from 'react';
import TeamMemberInfo from './TeamMemberInfo';

const TeamMemberInfoController: React.FC<React.PropsWithChildren<{}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = () => {
    return (
        <div style={{ height: '100%' }}>
            <TeamMemberInfo />
        </div>
    )
}

export default TeamMemberInfoController;