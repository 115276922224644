import AddIcon from '@mui/icons-material/Add';
import { IconButton, List, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TeamHeaderRole from './TeamHeaderRole';

const TeamRoleList: React.FC<{ addRole: () => void } & React.PropsWithChildren<{ children }> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ addRole, children }) => {
    const { t } = useTranslation('common')

    return (
        <div style={{ height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' flexWrap='nowrap' alignItems='center'>
                <Typography variant="h5">{t('TeamRoles.Title')}</Typography>
                <Tooltip title={"Add Role"}>
                    <IconButton onClick={() => addRole()}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Paper elevation={2} style={{ height: 'calc(100% - 40px)' }}>
                <TeamHeaderRole />
                <PerfectScrollbar style={{ overflowX: 'hidden', overscrollBehavior: 'none' }}
                    options={{ suppressScrollX: true }}>
                    <List>
                        {children?.map((roleItem: any) => roleItem)}
                    </List>
                </PerfectScrollbar>
            </Paper>
        </div>
    )
}

export default TeamRoleList;