
import { Box, List, Typography } from '@mui/material';
import Tab from 'components/Shared/Tab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { default as restAPI, default as restApi } from 'services/rest-api';
import { ViewerLayers } from '../Helpers/ViewerLayers';
import { IssueElement } from './IssueElement';
import styles from './IssuesPanel.module.scss';

import { Viewer } from 'hsbshareviewer';
import IssueDialog from './IssueDialog';

enum Sides {
    Front = 1 << 0,
    Back = 1 << 1,
    Left = 1 << 2,
    Right = 1 << 3,
    Top = 1 << 4,
    Bottom = 1 << 5,
}


export default function IssuesPanel(props: IssuesPanelProps) {
    let { open, onClose, onDelete, issues, projectId, viewer } = props;
    let { t } = useTranslation('common');
    const [currIssues, setCurrIssues] = useState<any[]>();
    const [search, setSearch] = useState<string>(null);
    const [clickedIssue, setClickedIssue] = useState(null);

    const onPropertyChange = (id: string, propertyName: string, value: any) => {
        restApi.updateIssueProperty(id, propertyName, value === '' ? null : value).then((data) => {
            const issuesCopy = currIssues,
                index = issuesCopy.map((e) => e._id).indexOf(id);
            issuesCopy[index] = { ...data, index: `#${index}` };
            setCurrIssues([...issuesCopy]);
        });
    };

    const handleSearch = (query: string) => {
        setSearch(query);
    };

    useEffect(() => {
        if (!(search?.length > 0)) {
            // If no filter and no search set jsut return original array.
            if (!issues) setCurrIssues(null);

            let map = issues?.map((issue, index) => {
                return { ...issue, index: `#${index}` };
            });
            setCurrIssues(map);
        }
    }, [issues, search]);

    const handleIssueEvent = (e) => {
        let { issue, action } = e;
        if (action === 'click') setClickedIssue(issue);
    };

    useEffect(() => {
        viewer?.ToggleRenderLayer(open, ViewerLayers.ISSUES);
        let toolEvents = viewer?.ViewerEvents?.ToolEvents;
        toolEvents?.addListener('issue', handleIssueEvent);

        return () => {
            toolEvents?.removeListener('issue', handleIssueEvent);
        };
    }, [open, viewer]);

    const handleHover = (issue: any | null) => {
        viewer.IssueManager.highlightIssue(issue?._id);
    };

    const previewIssue = (issue: any) => {
        if (!issue)
            return;
        // Show issue
        let refs: { [key: string]: { ifcid?: string; referenceId?: string }[] } =
            typeof issue.modelReferences === 'string' ? JSON.parse(issue.modelReferences) : issue.modelReferences;
        let entities = Object.entries(refs).reduce((accu: any[], [key, value]: any) => {
            let model = viewer?.project?.EntityTable[key];
            if (!model) return accu;
            let ents = value.map((ref) => model[ref.referenceId]).filter((e) => e);
            accu.push(...ents);
            return accu;
        }, []);
        viewer.HideEverything();
        viewer.ShowEntity(entities);
        viewer.cameraUtils.FocusCameraOnEntities(entities, { sides: Sides.Top | Sides.Left | Sides.Front });
    };

    const handleClick = (issue: any | null) => {
        setClickedIssue(issue);
    };

    const handleSubmit = (issue: any[] | null) => {
        setClickedIssue(null);
        let map = issue.concat(currIssues).map((issue, index) => {
            return { ...issue, index: `#${index}` };
        });
        setCurrIssues(map);
    };

    const handleDeleteIssue = (issueId: string) => {
        restAPI.deleteNode(issueId).then((result) => {
            setCurrIssues(currIssues.filter((issue) => issue._id !== issueId));
            onDelete?.(issueId);
        }).catch(err => {
            console.error(err);
        });
    };

    const handleCreateIssue = () => {
        setClickedIssue({ modelReferences: JSON.stringify(props.selection ? getEntityReferences(props.selection) : getEntityReferences(props.viewer.getEntities())) });
    };

    const getEntityReferences = (entities: any[]) => {
        if (!entities) {
            return null;
        }

        const references: Record<string, { referenceId: string, ifcId: string }[]> = {};

        for (const entity of entities) {
            const modelId = entity?.hsbmodel?.modelId;
            if (!modelId) continue;

            references[modelId] ??= [];

            references[modelId].push({
                referenceId: entity.handle,
                ifcId: entity.ifcid
            });
        }

        return Object.keys(references).length ? references : null;
    };

    return (
        <Tab
            open={open}
            direction={'right'}
            onClose={onClose}
            title={t('IssuesPanel.Issues')}
            onSearch={handleSearch}
            onAddition={handleCreateIssue}
            classes={{ paper: styles.sidePanel }}>
            <List classes={{ root: styles.list }}>
                {currIssues
                    ?.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
                    .map((issue) => (
                        <IssueElement
                            onHover={handleHover}
                            onClick={handleClick}
                            handlePreview={previewIssue}
                            modelviewer={viewer}
                            selected={issue._id === clickedIssue?._id}
                            onClose={() => { setClickedIssue(null); }}
                            key={issue._id}
                            issue={issue}
                            onIssueDelete={handleDeleteIssue}
                            projectId={projectId}
                        />
                    ))}
            </List>
            {
                !(currIssues?.length > 0) && (
                    <Box className={styles['no-content']}>
                        <Typography variant='subtitle1'>{t('IssuesPanel.NoIssues')}</Typography>
                    </Box>
                )
            }
            <IssueDialog
                entityTable={viewer?.project?.EntityTable}
                screenCapture={viewer?.screenCapture}
                issue={clickedIssue}
                projectId={projectId}
                onClose={() => {
                    setClickedIssue(null);
                }}
                onSubmit={handleSubmit}
                onPropertyChange={onPropertyChange}
            />
        </Tab >
    );
}

export interface IssuesPanelProps {
    open: boolean;
    projectId: string;
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    onDelete: (issue: any) => void;
    issues?: any[];
    selection?: any[];
    viewer: Viewer;
}
