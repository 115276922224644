import { Close } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EmailIcon from '@mui/icons-material/Email'
import FolderIcon from '@mui/icons-material/Folder'
import GroupsIcon from '@mui/icons-material/Groups'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import WarningIcon from '@mui/icons-material/Warning'
import { Avatar, Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import addedTeamRoleAtom from 'atoms/addedTeamRoleAtom'
import deletedTeamMemberAtom from 'atoms/deletedTeamMemberAtom'
import deletedTeamRoleAtom from 'atoms/deletedTeamRoleAtom'
import selectedTeamMemberAtom from 'atoms/selectedTeamMemberAtom'
import updatedTeamMemberAtom from 'atoms/updatedTeamMemberAtom'
import updatedTeamRoleAtom from 'atoms/updatedTeamRoleAtom'
import DialogBox from 'components/Shared/DialogBox'
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect'
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import useArray from 'hooks/useArray'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import styles from 'styles/TeamMemberInfo.module.css'
import amITeamAdminAtom from '../../atoms/amITeamAdmin'
import restAPI from '../../services/rest-api'

interface Statement {
    Actions: any[];
    Effect: string;
    Name: string;
    Resource: string;
}

const PermissionsItem: React.FC<{ permissions: Statement[] }> = ({ permissions }) => {
    const { t } = useTranslation('common');

    if (!(permissions?.length > 0))
        return null;

    let resources = permissions.reduce((init: { [key: string]: any }, permission) => {
        let arr = init[permission.Resource] ?? [];
        if (permission.Name !== 'none')
            arr.push(permission.Name);
        if (arr.length === 0)
            return init;
        return { ...init, [permission.Resource]: arr }
    }, {});

    const PermisionText = (permission: 'admin' | 'read' | 'edit') => {
        switch (permission) {
            case 'admin': return t('TeamMemberInfo.Admin');
            case 'edit': return t('TeamMemberInfo.Edit');
            case 'read': return t('TeamMemberInfo.Read');
        }
    }
    const translateKey = (key) => {
        switch (key) {
            case 'team': return t('TeamMemberInfo.Team');
            case 'document': return t('TeamMemberInfo.Document');
            case 'issue': return t('TeamMemberInfo.Issue');
            case 'hsbstacking': return t('TeamMemberInfo.HsbStacking');
        }
        return null;
    }

    return (
        <Box>
            {Object.entries(resources).map(([key, values], index) => {
                let icon;
                switch (key) {
                    case 'document': icon = <FolderIcon color='action' key='icon' />; break;
                    case 'issue': icon = <WarningIcon color='action' key='icon' />; break;
                    case 'team': icon = <GroupsIcon color='action' key='icon' />; break;
                    case 'hsbstacking': icon = <LocalShippingIcon color='action' key='icon' />; break;
                }
                return (
                    <Box key={`permission-${index}`} className={styles.permissionBox}>
                        {icon}
                        <Typography key='description' noWrap variant="subtitle1" className={styles.memberPermissions}>
                            {`${(values).map(PermisionText).join('/')} ${translateKey(key)}`}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

const TeamMemberInfo: React.FC<React.PropsWithChildren<{}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = () => {
    const { t } = useTranslation('common');
    const [, setDeletedTeamMember] = useRecoilState(deletedTeamMemberAtom);
    // Form data
    const { control, setValue } = useForm({
        defaultValues: {
            role: ''
        },
        mode: 'onChange'
    });
    const updatedTeamRole = useRecoilValue(updatedTeamRoleAtom);
    // policies
    const { items, setItems } = useArray([]);
    // Selected policy
    const [selectedPolicy, setSelectedPolicy] = useState<any>();
    // Snackbar
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' });
    // Current selected team member
    const [selectedTeamMember, setSelectedTeamMember] = useRecoilState(selectedTeamMemberAtom);
    // Is Team Admin
    const amITeamAdmin = useRecoilValue(amITeamAdminAtom);
    const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false);
    const [, setUpdatedTeamMember] = useRecoilState(updatedTeamMemberAtom);
    const addedTeamRole = useRecoilValue(addedTeamRoleAtom);
    const deletedTeamRole = useRecoilValue(deletedTeamRoleAtom);

    /**
     * Checks and returns the proper translation for a policy role.
     * @param role The role to be translated
     * @returns The correct translation for the given role
     */
    const translateRole = (role: string): string => {
        switch (role) {
            case 'Architect': return t('TeamMemberInfo.Architect')
            case 'Site Supervisor': return t('TeamMemberInfo.SiteSupervisor')
            case 'BIM Manager': return t('TeamMemberInfo.BIMManager')
            case 'Executive': return t('TeamMemberInfo.Executive')
            case 'Designer': return t('TeamMemberInfo.Designer')
            case 'Team Admin': return t('TeamMemberInfo.TeamAdmin')
            case 'Team User': return t('TeamMemberInfo.TeamUser')
            case 'Invalid':
            case '':
            case null:
            case undefined: return t('TeamMemberInfo.NoRole')
            default: return role;
        }
    }

    useEffect(() => {
        if (selectedTeamMember) {
            if (items.map((item) => item._id).includes(selectedTeamMember.roleId)) {
                setValue('role', selectedTeamMember.roleId);
            }
        }
    }, [items, selectedTeamMember?.role, setValue, selectedTeamMember]);

    useEffect(() => {
        if (!updatedTeamRole) {
            return;
        }
        if (selectedTeamMember.roleId === updatedTeamRole.roleId && updatedTeamRole.role) {
            setSelectedTeamMember({ ...selectedTeamMember, role: updatedTeamRole.role, roleId: updatedTeamRole.roleId });
        }
        getTeamPolicyRoles(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedTeamRole]);

    useEffect(() => getTeamPolicyRoles(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [addedTeamRole, deletedTeamRole]);

    useEffect(() => {
        let mounted = true;
        if (!selectedTeamMember?.teamID) {
            return;
        }

        getTeamPolicyRoles(mounted);

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeamMember]);

    const getTeamPolicyRoles = (mounted) => {
        if (!selectedTeamMember?.teamID) {
            return;
        }
        restAPI.getTeamPolicyRoles(selectedTeamMember.teamID).then(policies => {
            if (!mounted)
                return;
            setItems(policies)
            const selectPolicyStatements = policies?.find(policy => policy._id.localeCompare(selectedTeamMember.roleId, 'en', { sensitivity: 'base' }) === 0)?.Statements;
            setSelectedPolicy(selectPolicyStatements);
        }).catch(error => {
            console.error('getTeamPolicyRoles error: ' + error)
        })
    }

    /**
     * Handle the role change for the selected member
     * @param event Event that triggered the role change
     * @param value New role value
     */
    const handleRoleChange = (event: any) => {
        const selectedRoleId = event.target?.value;
        const selectedRoleName = items?.find(policy => policy._id === selectedRoleId)?.Name;
        setSelectedTeamMember({ ...selectedTeamMember, role: selectedRoleName, roleId: selectedRoleId });
        restAPI.setUserTeamPolicyRoleId(selectedTeamMember?.userId, selectedTeamMember?.teamID, selectedRoleId).then(result => {
            setUpdatedTeamMember({ ...selectedTeamMember, role: selectedRoleName, roleId: selectedRoleId });
            setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.MemberTeamRoleUpdate', { userName: selectedTeamMember?.username, newValue: selectedRoleName }) })
        }).catch(error => {
            if (error?.response?.data?.error === "the team needs at least one team admin") {
                setSelectedTeamMember({ ...selectedTeamMember })//trigger a refresh
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AtLeastOneTeamAdmin') })
            }
            console.error('setUserTeamPolicyRoleId error: ' + error)
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.UpdateTeamRolePermission') })
        })
    }

    /**
     * Deletes a member from a team
     * @returns 
     */
    const deleteMember = async () => {
        setShowRemoveMemberDialog(false);
        try {
            const result = await restAPI.removeUserFromTeam(selectedTeamMember?.userId, selectedTeamMember?.teamID);
            if (result?.teamModified) {
                setDeletedTeamMember({ userId: selectedTeamMember?.userId, teamID: selectedTeamMember?.teamID });
                setSelectedTeamMember(null)
                return setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.HasBeenRemovedFromTeam', { userName: selectedTeamMember?.username }) })
            }
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.RemovingUserFromTeam') })
        } catch (error) {
            if (error === 'failed at least one team admin validation.') {
                return setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.AtLeastOneTeamAdmin') })
            }
            console.error(error);
            setSnackBar({ open: true, severity: 'error', message: t('ErrorInfo.RemovingUserFromTeam') })
        }
    }

    const handleResendInvitation = async (e: any) => {
        try {
            const result = await restAPI.resendInvitation(selectedTeamMember?.email);
            if (result) {
                setSnackBar({ open: true, severity: 'success', message: `${t('TeamMemberInfo.ReminderSent', { user: selectedTeamMember.email })}` });
                return;
            }
            setSnackBar({ open: true, severity: 'error', message: `${t('TeamMemberInfo.ReminderError', { user: selectedTeamMember.email })}` })
        }
        catch (err) {
            setSnackBar({ open: true, severity: 'error', message: `${t('TeamMemberInfo.ReminderError', { user: selectedTeamMember.email })}` })
            if (window.location.hostname === 'localhost') {
                console.error("TeamMemberInfo::handleResendInvitation(): Something went wrong.", err);
            }
        }
    }

    const handleClose = () => {
        setShowRemoveMemberDialog(false);
    }

    const getRoleOptions = () => {
        return items?.map(policy =>
            <FormInputSelectMenuItem key={policy?._id} value={policy?._id}>{translateRole(policy?.Name)}</FormInputSelectMenuItem>
        )
    }

    return (
        <Box style={{ height: '100%' }}>
            {selectedTeamMember ?
                <Box className={styles.memberInfo}>
                    <Stack direction='row' justifyContent='flex-start' flexWrap='nowrap' alignItems='center' minHeight={'40px'}>
                        <Typography variant="h5">{t('TeamMemberInfo.Title')}</Typography>
                    </Stack>
                    <Paper elevation={2} classes={{ root: `${styles.memberInfoPaper}` }}>
                        <Stack classes={{ root: styles.userInfo }} direction='column' justifyContent='flex-start' alignItems='center'>
                            <Avatar alt="Avatar" src={selectedTeamMember.userImage} classes={{ root: styles.memberInfoAvatar }} sx={{ backgroundColor: selectedTeamMember?.backgroundColor ?? 'lightgray' }}>
                                {selectedTeamMember.isActive ?
                                    selectedTeamMember.username?.substring(0, 1)?.toUpperCase() :
                                    <PersonOffIcon classes={{ root: styles.inactiveIcon }} />}
                            </Avatar>
                            <div>
                                <Typography noWrap variant="h6" classes={{ root: styles.memberInfoText }} >
                                    {selectedTeamMember.username}
                                </Typography>
                            </div>
                            {selectedTeamMember.isActive ?
                                <Box>
                                    <Typography noWrap variant="body1" classes={{ root: styles.memberInfoText }} >
                                        {selectedTeamMember.email}
                                    </Typography>

                                    <Typography noWrap variant="h6" classes={{ root: styles.memberInfoText }} sx={{ whiteSpace: 'pre-line' }}>
                                        {selectedTeamMember.telephone ?? '\n'}
                                    </Typography>
                                </Box>
                                :
                                <Box className={styles.inactiveBox}>
                                    <Typography noWrap variant="h6" classes={{ root: styles.memberInfoText }} >
                                        {"User is inactive"}
                                    </Typography>
                                    {amITeamAdmin &&
                                        <Button variant='contained' onClick={handleResendInvitation} startIcon={<EmailIcon />} >
                                            {t('TeamMemberInfo.Reminder')}
                                        </Button>
                                    }
                                </Box>
                            }
                        </Stack>
                        <Stack style={{ padding: '0 30px 30px 30px' }} direction='column' justifyContent='flex-start' alignItems='flex-start'>
                            {amITeamAdmin ?

                                <FormInputSelect onChange={handleRoleChange} search name={'role'} control={control} classes={{ container: styles.roleSelect }} placeholder={t('TeamMemberInfo.NoRole')}>
                                    {getRoleOptions()}
                                </FormInputSelect>
                                :
                                selectedTeamMember && <Typography noWrap variant="h6" classes={{ root: `${styles.memberRole} ${!selectedTeamMember.role && styles.invalidMemberRole}` }} >
                                    {translateRole(selectedTeamMember?.role)}
                                </Typography>
                            }

                            <PermissionsItem permissions={selectedPolicy} />
                            {amITeamAdmin &&
                                <Button onClick={() => setShowRemoveMemberDialog(true)} color="error" variant="contained" classes={{ root: styles.removeButton }} startIcon={<DeleteIcon />}>
                                    {t('TeamMemberInfo.RemoveFromTeam')}
                                </Button>}
                        </Stack>
                    </Paper>
                    <DialogBox aria-labelledby="delete-team-dialog" open={showRemoveMemberDialog} onClose={handleClose}>
                        <DialogTitle variant={'error'}>
                            Delete {selectedTeamMember.email}
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            Are you sure you want to remove {selectedTeamMember.email} from this team? This can not be reversed.
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" color="error" onClick={(event) => { deleteMember() }}>Remove</Button>
                        </DialogActions>
                    </DialogBox>
                </Box>
                : null}
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </Box>
    )
}

export default TeamMemberInfo;
