import React, { useState } from 'react';
import { IssueState } from '../../types/enums';
import styles from '../ModelViewer/Issues/IssueItem.module.scss';
import { useTranslation } from 'react-i18next';
import { IssueStateChip } from './StateChip';
import { Box, ListItemButton, Skeleton } from '@mui/material';
import OneLineTextDiv from 'components/Shared/OneLineTextDiv';
import UserSearch from './UserSearch';
import restAPI from '../../services/rest-api';
import SnackBarMessage, { ISnackBarData } from '../Shared/snackBar/SnackBarMessage';
import { useNavigate } from 'react-router';

interface IIssueItem {
    id: string;
    projectId: string;
    state: IssueState;
    image: string;
    title: string;
    assignedTo: string;
    updatedText: string;
    children?: React.ReactNode;
}

const IssueItem: React.FC<IIssueItem> = ({ id, projectId, state, image, title, assignedTo, updatedText }) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [assignedToEmail, setAssignedToEmail] = useState(assignedTo);
    const [assignedToEditMode, setAssignedToEditMode] = useState(false);
    const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: 'success', message: '' });

    const autoCompleteCommit = async (assignedToUpdated) => {
        try {
            setAssignedToEmail(assignedToUpdated.email);
            setAssignedToEditMode(!assignedToEditMode);
            if (assignedTo === assignedToUpdated.email) {
                return;
            }
            await restAPI.updateIssueAssignedTo(id, assignedToUpdated._id);
            setSnackBar({ open: true, severity: 'success', message: t('IssueItem.UpdatedIssueAssignedTo') });
        } catch (err) {
            setSnackBar({ open: true, severity: 'error', message: t('IssueItem.Error') });
        }
    };

    const issueStateCommit = async (newState: IssueState) => {
        try {
            if (state === newState) {
                return;
            }
            await restAPI.updateIssueState(id, newState);
            setSnackBar({ open: true, severity: 'success', message: t('IssueItem.UpdatedIssueState') });
        } catch (err) {
            setSnackBar({ open: true, severity: 'error', message: t('IssueItem.Error') });
        }
    };

    const onBlur = (event) => {
        setAssignedToEditMode(!assignedToEditMode);
    };

    const handleLink = () => {
        if (assignedToEditMode) {
            return;
        }
        navigate(`/project/${projectId}?rpanel=issues`);
    };

    return (
        <Box>
            <ListItemButton className={styles.container} onClick={handleLink}>
                <div className={styles.main}>
                    {id ? <img className={styles.dim} alt="" src={image} /> : <Skeleton style={{ height: '90px' }} variant="rectangular" />}
                </div>
                <div className={styles.grow}>
                    {id ? (
                        <OneLineTextDiv text={title} className={styles.title} />
                    ) : (
                        <Skeleton style={{ width: '85%' }} className={styles.title} variant="text" />
                    )}
                    {id ? (
                        !assignedToEditMode && (
                            <div onClick={(e) => {
                                e?.stopPropagation();
                                setAssignedToEditMode(!assignedToEditMode)
                            }}>
                                <OneLineTextDiv
                                    tooltip={t('IssueList.AssignedTo') + assignedToEmail}
                                    text={t('IssueList.AssignedTo')}
                                    hightLightText={assignedToEmail}
                                    className={styles.subtitle}
                                />
                            </div>
                        )
                    ) : (
                        <Skeleton style={{ width: '65%' }} className={styles.subtitle} variant="text" />
                    )}
                    {id
                        ? assignedToEditMode && (
                            <div>
                                <UserSearch
                                    projectId={projectId}
                                    onCommit={autoCompleteCommit}
                                    onBlur={onBlur}
                                    text={t('IssueList.AssignedTo') + assignedTo}
                                    className={styles.subtitle}
                                />{' '}
                            </div>
                        )
                        : null}
                    {id ? (
                        <OneLineTextDiv text={updatedText} className={styles.subtitle} />
                    ) : (
                        <Skeleton style={{ width: '65%' }} className={styles.subtitle} variant="text" />
                    )}
                </div>
                <div style={{ marginTop: '3px' }} className={styles.last}>
                    {id ? (
                        <IssueStateChip onStatusChange={issueStateCommit} initialState={state} />
                    ) : (
                        <Skeleton style={{ width: '60px', height: '50px', marginTop: '-7px' }} variant="text" />
                    )}
                </div>
            </ListItemButton>
            <SnackBarMessage
                severity={stateSnackBar.severity}
                message={stateSnackBar.message}
                open={stateSnackBar.open}
                onSetOpen={setSnackBar}
            />
        </Box>
    );
};

export default IssueItem;
