import TextareaAutosize from '@mui/base/TextareaAutosize';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, InputBaseComponentProps, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './EditableInput.module.scss';

export interface EditableInputProps {
    text: string;
    type: 'text' | 'textarea';
    inputProps?: InputBaseComponentProps;
    textfieldProps?: TextFieldProps;
    disabled?: boolean;
    cancelButton?: boolean;
    onSave?: (text: string) => void;
    onCancel?: (text: string) => void;
}

export default function EditableInput(props: React.PropsWithChildren<EditableInputProps>) {
    let { text, type, onSave, onCancel, inputProps, cancelButton, textfieldProps, disabled } = props;
    const { t } = useTranslation('common');
    const { register, handleSubmit, reset, formState, getValues } = useForm();
    const [editing, setEditing] = useState(false);
    const field = useRef(null);

    const handleValidSubmit = (e: any) => {
        setEditing(false);
        field.current?.blur();
        if (formState.dirtyFields?.text && onSave) {
            onSave(e.text);
        }
    };

    const handleCancel = (e: any) => {
        setEditing(false);
        reset();
        onCancel?.(e);
    };

    const handleBlur = (e: any) => {
        if (e.currentTarget.contains(e.relatedTarget)) return;
        setEditing(false);
        reset();
        onCancel?.(e);
    };

    const activateInput = (e: any) => {
        if (editing || disabled) {
            return;
        }
        setEditing(true);
        field.current?.focus() ?? e.target?.[0]?.focus();
    };

    useEffect(() => {
        reset({ text: text });
    }, [text, reset]);

    return (
        <form
            title={getValues().text}
            onSubmit={handleSubmit(handleValidSubmit)}
            onReset={handleCancel}
            onBlur={handleBlur}
            onAbort={handleCancel}
            onDoubleClick={activateInput}
            className={`${styles['inline-text-form']} ${type === 'textarea' ? styles['inline-text-form-vertical'] : ''}`}>
            {type === 'text' ? (
                <TextField
                    classes={{ root: `${styles['inline-text-textfield']} ${!editing ? styles['inline-text-input-disabled'] : ''}` }}
                    name="text"
                    variant="standard"
                    type={type}
                    inputRef={field}
                    required
                    disabled={disabled}
                    autoCapitalize="words"
                    inputProps={{
                        style: { width: !editing ? getValues().text?.length + 1 + 'ch' : 'auto' },
                        ...inputProps,
                        className: `${styles['inline-text-input']} ${inputProps?.className ?? ''}`,
                    }}
                    InputProps={{
                        endAdornment: type === 'text' && editing && (
                            <Box>
                                <IconButton
                                    title={t('Input.SaveTooltip')}
                                    className={styles['inline-text-action']}
                                    type="submit"
                                    id="submitButton">
                                    <CheckIcon />
                                </IconButton>
                                {cancelButton && (
                                    <IconButton
                                        title={t('Input.CancelTooltip')}
                                        className={styles['inline-text-action']}
                                        type="button"
                                        id="cancelButton"
                                        onClick={() => reset()}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ),
                    }}
                    {...textfieldProps}
                    {...register('text')}
                />
            ) : (
                <TextareaAutosize
                    ref={field}
                    className={`${styles['inline-text-input-area']} ${!editing ? styles['inline-text-input-disabled'] : ''}`}
                    name="text"
                    {...register('text')}
                    minRows={2}
                    autoFocus
                />
            )}
            {editing && type !== 'text' && (
                <Box className={styles['action-container']}>
                    <Button
                        title={t('Input.SaveTooltip')}
                        variant="contained"
                        className={styles['inline-text-submit']}
                        type="submit"
                        id="submitButton">
                        {t('Input.Save')}
                    </Button>
                    {cancelButton && (
                        <Button
                            title={t('Input.CancelTooltip')}
                            variant="outlined"
                            className={styles['inline-text-cancel']}
                            onClick={handleCancel}
                            type="button"
                            id="cancelButton">
                            {t('Input.Cancel')}
                        </Button>
                    )}
                </Box>
            )}
            {!disabled && !editing && (
                <IconButton
                    title={t('Input.EditTooltip')}
                    className={styles[`inline-${type}-edit`]}
                    type="button"
                    id="editButton"
                    onClick={activateInput}>
                    <EditIcon />
                </IconButton>
            )}
        </form>
    );
}
