import { faHouse } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { FormInputSelect } from "components/Shared/FormComponents/FormInputSelect/FormInputSelect";
import { FormInputSelectMenuItem } from "components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem";
import ImageOrPlaceholder from "components/Shared/ImageOrPlaceholder/ImageOrPlaceholder.module";
import { ShareData, nodeLoaderService } from 'hsbshareviewer';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import restAPI from "services/rest-api";
import { ModelState, ModelStateValues, getChipLabel } from 'types/enums';
import propertiesStyles from '../../../styles/Properties.module.scss';
import { shareModelDefinition } from '../ModelViewer';
import { Revision } from './ModelsPanel';
import styles from "./ModelsPanel.module.scss";

export interface ModelItemProps {
    model: any;
    project: any;
    handleModelDelete: (e: any, model: any) => void;
    handleRevisionDelete: (e: any, revision: any) => void;
}

const ModelItem = (props: ModelItemProps) => {
    const { model, project, handleModelDelete, handleRevisionDelete } = props;
    let { t } = useTranslation("common");
    const [thumbnail, setThumbnail] = useState(null);
    const [revision, setRevision] = useState(props.model?.activeRevision);
    const [render, setRender] = useState(0);
    const { control } = useForm<any>({
        defaultValues: {
            state: model.status
        }, mode: "onChange"
    });

    useEffect(() => {
        if (props.model?.activeRevision) {
            setRevision(props.model.activeRevision);
            restAPI.getModelThumbnail(revision.projectId, revision.node.pathid).then((result) => {
                setThumbnail(result);
            }).catch((err) => {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                    console.error("ModelsPanel: Could not fetch revision thumbnail!", err);
                } else {
                    console.error("ModelsPanel: Could not fetch revision thumbnail!");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.model?.activeRevision]);

    const handleRevStatusUpdate = (status: ModelState, model: any) => {
        if (!model) { return; }
        setRender(1 + render);

        model.status = status;
        model.revisions.forEach(r => {
            ((r.node ??= {}).metadata.data.properties.status ??= {}).value = status;
            let shareData = new ShareData(r.node, project, "hsbshare.projectmodel", r.node.metadata.data);

            nodeLoaderService.updateModelNode(shareModelDefinition, shareData).then().catch((err) => {
                console.error(err);
            });
        });
    }

    const getStateOptions = () => {
        const states = ModelStateValues.map(s => {
            return <FormInputSelectMenuItem key={s} value={s} avatar={{ letter: s.substring(0, 1), styleClass: propertiesStyles[s.toLowerCase()] }} type={"iconRight"}>{getChipLabel(s, t)}</FormInputSelectMenuItem>
        })
        states.unshift(<FormInputSelectMenuItem key={"unset"} value={""}>{t("ModelsPanel.Unset")}</FormInputSelectMenuItem>);
        return states;
    }

    return (
        <Box key={revision.modelId} className={styles['model-list-container']}>
            <Accordion className={styles['model-container']} classes={{ expanded: model.revisions.length > 1 && styles['model-expanded'] }}>
                <AccordionSummary className={model.revisions.length === 1 ? styles['model-with-no-revisions'] : null} classes={{ root: styles['model-summary'], content: styles['model-summary-content'] }}>
                    <ImageOrPlaceholder imageURL={thumbnail} size="default" placeholderIcon={faHouse} />
                    <Box className={`${styles['model-summary-closed']}`}>
                        <Box>
                            <Typography className={styles['model-summary-title']}>{revision.modelName ?? t('ModelsPanel.Unset')}</Typography>
                            <Typography className={styles['model-summary-subtitle']}>{`${t("ModelsPanel.Revision")} ${revision.revision}`}</Typography>
                        </Box>
                        {
                            revision.description &&
                            <p className={styles['model-summary-description']}>{revision.description}</p>
                        }
                        <Box className={styles['model-summary-info']}>
                            <Box className={styles['model-summary-info-item']}>
                                <Box className={`${styles['model-summary-status-dot']} ${propertiesStyles[model.status]}`}></Box>
                                <FormInputSelect search control={control} name='state' classes={{ container: styles['state-selector'] }} onChange={(event) => handleRevStatusUpdate(event.target.value, model)} placeholder={t("ModelsPanel.Unset")}>
                                    {getStateOptions()}
                                </FormInputSelect>
                            </Box>
                            <Box className={styles['model-summary-info-item']}>
                                <AccountCircleIcon />
                                <Typography className={styles['model-summary-info-item-title']}>{revision.createdBy ?? t('ModelsPanel.Unassigned')}</Typography>
                            </Box>
                            <Box className={styles['model-summary-info-item']}>
                                <WatchLaterIcon />
                                <Tooltip title={new Date(revision.createdAt)?.toString()}>
                                    <Typography className={styles['model-summary-info-item-title']}>
                                        {
                                            revision.createdAt ? new Intl.DateTimeFormat().format(new Date(revision.createdAt)) : t('ModelsPanel.Unset')
                                        }
                                    </Typography>
                                </Tooltip>
                            </Box>
                            {
                                model.revisions.length > 1 &&
                                <Box className={styles['model-summary-info-item']}>
                                    <Box className={styles['model-summary-revision-amount']}>{`+${model.revisions.length - 1} ${model.revisions.length - 1 === 1 ? t('ModelsPanel.Revision') : t('ModelsPanel.Revisions')}`}</Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box className={styles['model-actions']}>
                        {
                            model.revisions.length > 1 &&
                            <Tooltip title={t('ModelsPanel.DeleteRevision')}>
                                <IconButton aria-label="delete revision" onClick={(e: any) => handleRevisionDelete(e, revision)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={t('ModelsPanel.DeleteModel')}>
                            <IconButton aria-label="delete model" onClick={(e: any) => handleModelDelete(e, model)}>
                                <DeleteSweepIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    {
                        model.revisions.filter((revision, revIndex) => revIndex > 0)
                            .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
                            .reverse()
                            .map((revision, revIndex) =>
                                <RevisionElement key={'revision-' + revIndex} revision={revision} handleRevisionDelete={handleRevisionDelete} />
                            )
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

const RevisionElement = (props: RevisionProps) => {
    const { t } = useTranslation('common');
    const { revision, handleRevisionDelete } = props;

    return (
        <Box key={revision.modelId} className={`${styles['model-details-container']}`}>
            <Typography className={styles['revision-summary-title']}>{`${t("ModelsPanel.Revision")} ${revision.revision}`}</Typography>
            {
                revision.description &&
                <p className={styles['model-summary-description']}>{revision.description}</p>
            }
            <Box className={styles['model-summary-info']}>
                <Box className={styles['model-summary-info-item']}>
                    <AccountCircleIcon />
                    <Typography className={styles['model-summary-info-item-title']}>{revision.createdBy ?? t('ModelsPanel.Unassigned')}</Typography>
                </Box>
                <Box className={styles['model-summary-info-item']}>
                    <WatchLaterIcon />
                    <Tooltip title={new Date(revision.createdAt)?.toString()}>
                        <Typography className={styles['model-summary-info-item-title']}>
                            {
                                revision.createdAt ? new Intl.DateTimeFormat().format(new Date(revision.createdAt)) : t('ModelsPanel.Unset')
                            }
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box className={styles['model-actions']}>
                <Tooltip title={t('ModelsPanel.DeleteRevision')}>
                    <IconButton aria-label="delete revision" onClick={(e: any) => handleRevisionDelete(e, revision)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>)
};

interface RevisionProps {
    revision: Revision;
    handleRevisionDelete: (e: any, revision: Revision) => void;
}

export default ModelItem;