import { InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './FormInput.module.css';
import { FormInputPhoneProps } from "./FormInputProps";

export const FormInputPhone = ({ name, control, country, label }: FormInputPhoneProps) => {
  const isValid = (value, country) => {
    if (value.match(/12345/)) {
      return 'Invalid value: ' + value + ', ' + country.name;
    } else if (value.match(/1234/)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div style={{ display: 'inline-block' }}>
      {label &&
        <InputLabel className={styles.label}>{label}</InputLabel>}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, onBlur },
        }) => (
          <PhoneInput
            containerStyle={{ width: '100%' }}
            inputStyle={{ width: '100%' }}
            country={country || 'us'}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            enableSearch={true}
            isValid={isValid}
            defaultErrorMessage="Enter a valid phone number"
          />
        )}
      />
    </div>
  );
};