import PersonOffIcon from '@mui/icons-material/PersonOff'
import { Avatar, Box, ButtonBase, Typography } from '@mui/material'
import React from 'react'
import { useRecoilState } from 'recoil'
import selectedTeamMemberAtom, { ISelectedTeamMember } from '../../atoms/selectedTeamMemberAtom'
import styles from '../../styles/TeamMemberItem.module.scss'

const TeamMemberItem: React.FC<ISelectedTeamMember> = (props) => {
    let { userId, username, userImage, role, index, backgroundColor, isActive } = props;
    const [selectedTeamMember, setSelectedTeamMember] = useRecoilState(selectedTeamMemberAtom);

    return (
        <Box className={styles.memberButton}>
            <ButtonBase onClick={() => setSelectedTeamMember(props)} sx={{ width: '100%' }}>
                <div className={`${styles.box} ${selectedTeamMember?.index === index ? 'theme-selected-item' : ''}`} key={userId}>
                    <Avatar alt="Avatar" src={userImage} sx={{ backgroundColor: backgroundColor ?? 'lightgray' }} classes={{ root: styles.memberAvatar }}>
                        {isActive ?
                            username?.substring(0, 1)?.toUpperCase() :
                            <PersonOffIcon classes={{ root: styles.inactiveIcon }} />}
                    </Avatar>

                    <Typography noWrap variant="subtitle1" fontWeight={selectedTeamMember?.index === index && 700} className={`${styles.memberInfo}`}  >
                        {username}
                    </Typography>

                    <Typography noWrap variant="body2" className={`${styles.memberInfo}`}>
                        {role}
                    </Typography>
                </div>
            </ButtonBase>
        </Box>
    )
}

export default TeamMemberItem
