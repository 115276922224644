import Close from '@mui/icons-material/Close'
import { TabContext, TabList } from '@mui/lab'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, Tab } from '@mui/material'
import DialogBox from 'components/Shared/DialogBox'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import { UserSettingsContext } from 'context/UserSettingsContext'
import useSession from 'hooks/useSession'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import config from 'services/config'
import styles from 'styles/UserSettings.module.scss'
import constants from '../../services/constants'
import restAPI from '../../services/rest-api'
import { IShareSession } from '../../types/IShareSession'
import GeneralSettingsPanel, { GeneralSettings } from './GeneralSettings'
import NotificationSettingsPanel from './NotificationSettings'
import PreferenceSettingsPanel, { Languages, PreferenceSettings, UnitSystem } from './PreferenceSettings'

function a11yProps(index: any) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    }
}

// Declare a flatten function that takes
// object as parameter and returns the
// flatten object
const flattenObj = (ob) => {
    // The object which contains the
    // final result
    let result = {}

    // loop through the object "ob"
    for (const i in ob) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i])
            for (const j in temp) {
                // Store temp in result
                result[i + '-' + j] = temp[j]
            }
        }

        // Else store ob[i] in result directly
        else {
            result[i] = ob[i]
        }
    }
    return result
}

export interface UserSettingsValues {
    generalSettings?: GeneralSettings
    preferenceSettings?: PreferenceSettings
    notificationSettings?: Partial<{ [key: string]: boolean }>
}

export default function UserSettings(props: UserSettingsProps) {
    const { open, close, onSubmit } = props
    const scrollbarRef = useRef<PerfectScrollbar>(null)
    const { t, i18n } = useTranslation('common')
    const useFormHook = useForm<UserSettingsValues>({
        defaultValues: {
            generalSettings: {
                name: '',
                email: '',
                phone: '',
                team: '',
                teams: null,
                registered: '',
            },
        },
        mode: 'onChange',
    })
    const { handleSubmit, formState, reset, getValues } = useFormHook
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({
        open: false,
        severity: 'success',
        message: '',
    })
    const [tab, setTab] = React.useState('general')
    const [, setSearchParams] = useSearchParams()
    const location = useLocation()
    const { getSession, setSession, isGuest } = useSession()
    const getSessionCallback = useCallback(getSession, [getSession])
    const { setSettings } = useContext(UserSettingsContext)

    const handleChange = (event: any, newValue: any) => {
        setTabAndRouteFromId(newValue)
    }

    const onClose = () => {
        if (Object.keys(formState.dirtyFields).length > 0)
            setSnackBar({
                open: true,
                severity: 'info',
                message: t('UserSettings.SettingsReset'),
            })
        if (close) close()
        reset()
        setSubRoute(-1)
    }

    const setSubRoute = (tab: number) => {
        const queryParams = new URLSearchParams(location.search)
        switch (tab) {
            case 0:
                queryParams.set('settings', 'general')
                break
            case 1:
                queryParams.set('settings', 'preferences')
                break
            case 2:
                queryParams.set('settings', 'notifications')
                break
            default:
                queryParams.delete('settings')
        }
        setSearchParams(queryParams.toString())
    }

    const getFormId = (id?: number) => {
        switch (id || tab) {
            case 0:
                return 'form-settings-general'
            case 1:
                return 'form-settings-preferences'
            case 2:
                return 'form-settings-notifications'
            default:
                return ''
        }
    }

    const handleNewGeneralSettings = (changedSettings: any, values: GeneralSettings): [Promise<any>[], (data?: any) => void] => {
        let promises = []
        Object.keys(changedSettings).forEach((key) => {
            switch (key) {
                case 'name':
                    promises.push(restAPI.setUsername(values.name))
                    break
                case 'team':
                    promises.push(restAPI.setUserActiveTeam(values.team))
                    break
                case 'avatar':
                    promises.push(restAPI.setUserAvatar(values.avatar?.URI, values.avatar?.color))
                    break
                case 'phone':
                    promises.push(restAPI.setUserPhone(values.phone))
                    break
            }
        })
        return [
            promises,
            (result) => {
                if (changedSettings.name || changedSettings.avatar) {
                    let currentSession: IShareSession = getSessionCallback()
                    if (changedSettings.name) currentSession.name = values['name']
                    if (changedSettings.avatar) currentSession.avatar = values['avatar']
                    setSession(currentSession, new Date(currentSession.exp))
                }
            },
        ]
    }

    const handleNewNotificationSettings = (changedSettings: any, values: any): [Promise<any>[], (data?: any) => void] => {
        let flatValues = flattenObj(values)
        let promises = [restAPI.setNotifSettings(flatValues)]
        let then = null
        return [promises, then]
    }

    const handleNewPreferenceSettings = (changedSettings: any, values: PreferenceSettings): [Promise<any>[], (data?: any) => void] => {
        let promises = []
        Object.keys(changedSettings).forEach((key) => {
            switch (key) {
                case 'language':
                    promises.push(restAPI.setLanguage(values.language))
                    break
                case 'unitSettings':
                case 'decimalPointSettings':
                    promises.push(restAPI.setUnitSettings(values.unitSettings))
                    break
            }
        })
        return [
            promises,
            (result) => {
                i18n.changeLanguage(values.language)
                localStorage.setItem(constants.languageStorage, values.language)
            },
        ]
    }

    /**
     * Handle the submitted form. Checks the touched fields and sends the necessary calls to the backend.
     * @param data
     */
    const onFormSubmit = (...data: any) => {
        let values = getValues()
        let touchedFields = { ...formState.touchedFields, ...formState.dirtyFields }
        let promises: Promise<any>[] = [],
            thens: ((data?: any) => void)[] = []

        if (touchedFields.generalSettings) {
            let [promise, then] = handleNewGeneralSettings(touchedFields.generalSettings, values.generalSettings)
            promises.push(...promise)
            if (then) thens.push(then)
        }

        if (touchedFields.notificationSettings) {
            let [promise, then] = handleNewNotificationSettings(touchedFields.notificationSettings, values.notificationSettings)
            promises.push(...promise)
            if (then) thens.push(then)
        }

        if (touchedFields.preferenceSettings) {
            let [promise, then] = handleNewPreferenceSettings(touchedFields.preferenceSettings, values.preferenceSettings)
            promises.push(...promise)
            if (then) thens.push(then)
        }

        Promise.all(promises)
            .then((result) => {
                thens.forEach((func) => {
                    func(result)
                })
                setSnackBar({
                    open: true,
                    severity: 'success',
                    message: t('UserSettings.SettingsSaved'),
                })
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    severity: 'error',
                    message: t('userSettings.Error'),
                })
            })
        reset(values)
        setSettings(values)

        if (close) close()

        if (onSubmit) onSubmit()

        setSubRoute(-1)
    }

    const onInvalidSubmit = (e: any) => {
        console.error(e)
        setSnackBar({
            open: true,
            severity: 'error',
            message: t('userSettings.Error'),
        })
    }

    const setTabAndRouteFromId = (value: any) => {
        switch (value) {
            case 'general':
                setTab(value)
                setSubRoute(0)
                break
            case 'preferences':
                setTab(value)
                setSubRoute(1)
                break
            case 'notifications':
                setTab(value)
                setSubRoute(2)
                break
            default:
                setTab('general')
                setSubRoute(0)
                break
        }
    }

    useEffect(() => {
        if (!open) return

        const currentSession: IShareSession = getSessionCallback()
        if (!currentSession) {
            return
        }

        const queryParams = new URLSearchParams(location.search)
        let settings = queryParams.get('settings')
        setTabAndRouteFromId(settings)
        let mount = true
        if (!isGuest())
            // Fetch settings
            Promise.all([
                restAPI.getUserInfo('phone', 'avatar', 'activeTeam', 'registered'),
                restAPI.getUserTeams(),
                restAPI.getUserSettings(),
                restAPI.getNotifSettings(),
            ])
                .then(([userInfo, userTeams, prefSettings, notifSettings]) => {
                    if (!mount) return

                    let fetchedSettings: UserSettingsValues = {}
                    // General Settings
                    let active =
                        userTeams.data.teams.find((t) => {
                            return t.teamID === userInfo.activeTeam
                        }) || userTeams.data.teams[0]
                    fetchedSettings.generalSettings = {
                        avatar: currentSession.avatar ?? {
                            URI: userInfo?.avatar?.URI || '',
                            letter: currentSession?.name?.slice(0, 1)?.toUpperCase() || '',
                            color: userInfo?.avatar?.backgroundColor || 'hsl(' + Math.random() * 359 + ',70%,68%)',
                        },
                        name: currentSession.name,
                        email: currentSession.email,
                        team: active.teamID ?? '',
                        /**
                         * @todo: API call for team
                         */
                        phone: userInfo.phone,
                        teams: userTeams.data?.teams,
                        registered: userInfo.registered,
                    }
                    //Pref Settings
                    fetchedSettings.preferenceSettings = {
                        language: prefSettings.data?.language || Languages.English,
                        unitSettings: prefSettings.data?.unitSettings || {
                            system: UnitSystem.METRIC,
                            length: 'mm',
                            area: 'mm2',
                            volume: 'mm3',
                            weight: 'kg',
                            decimals: 2,
                        },
                    }
                    // Notif Settings
                    let notifInput = {}
                    // Function: createNestedObject( base, names[, value] )
                    //   base: the object on which to create the hierarchy
                    //   names: an array of strings contaning the names of the objects
                    //   value (optional): if given, will be the last object in the hierarchy
                    // Returns: the last object in the hierarchy
                    var createNestedObject = function (base, names, value) {
                        // If a value is given, remove the last name and keep it for later:
                        var lastName = arguments.length === 3 ? names.pop() : false

                        // Walk the hierarchy, creating new objects where needed.
                        // If the lastName was removed, then the last object is not set yet:
                        for (var i = 0; i < names.length; i++) {
                            base = base[names[i]] = base[names[i]] || {}
                        }

                        // If a value was given, set it to the last name:
                        if (lastName) base = base[lastName] = value

                        // Return the last object in the hierarchy:
                        return base
                    }

                    Object.entries(notifSettings).forEach(([key, value]) => {
                        createNestedObject(notifInput, key.split('-'), value)
                    })
                    fetchedSettings.notificationSettings = notifInput
                    useFormHook.reset(fetchedSettings, { keepDirty: false })
                    setSettings(fetchedSettings)
                })
                .catch((reason) => {
                    console.error('Could not fetch settings!', reason)
                })

        return () => {
            mount = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <form onSubmit={handleSubmit(onFormSubmit, onInvalidSubmit)}>
            <DialogBox aria-labelledby="User Settings" onClose={onClose} open={open}>
                <DialogTitle>
                    {t('UserSettings.Title')}
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '700px !important' }}>
                    <TabContext value={tab}>
                        <Box>
                            <TabList variant="fullWidth" onChange={handleChange}>
                                <Tab label={t('UserSettings.General')} value="general" {...a11yProps(0)} />
                                <Tab label={t('UserSettings.Preferences')} value="preferences" {...a11yProps(1)} />
                                <Tab label={t('UserSettings.Notifications')} value="notifications" {...a11yProps(2)} />
                            </TabList>
                        </Box>
                        <PerfectScrollbar ref={scrollbarRef} className={styles.scrollBox}>
                            <TabPanel value="general" sx={{ padding: '0', margin: '18px 0' }}>
                                <GeneralSettingsPanel formId={getFormId(0)} useForm={useFormHook} values={getValues().generalSettings} />
                            </TabPanel>
                            <TabPanel value="preferences" sx={{ padding: '0', margin: '18px 0' }}>
                                <PreferenceSettingsPanel
                                    formId={getFormId(1)}
                                    useForm={useFormHook}
                                    values={getValues().preferenceSettings}
                                />
                            </TabPanel>
                            <TabPanel value="notifications" sx={{ padding: '0', margin: '18px 0' }}>
                                <NotificationSettingsPanel
                                    formId={getFormId(2)}
                                    useForm={useFormHook}
                                    values={getValues().notificationSettings}
                                />
                            </TabPanel>
                        </PerfectScrollbar>
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type="submit" form={getFormId()} onClick={onFormSubmit}>
                        Submit
                    </Button>
                </DialogActions>
                <Box className={styles['version-info']}>
                    {config.version}
                </Box>
            </DialogBox>
            <SnackBarMessage
                severity={stateSnackBar.severity}
                message={stateSnackBar.message}
                open={stateSnackBar.open}
                onSetOpen={setSnackBar}
            />
        </form>
    )
}

export interface UserSettingsProps {
    onSubmit?: () => void
    open: boolean
    close: () => void
}
