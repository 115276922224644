import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import icon from 'assets/privateProject.png'
import HalfFooter from "components/Shared/Footer/HalfFooter"
import HsbShareLogo from 'components/Shared/HsbShareLogo'
import RoundedButton from "components/Shared/RoundedButton"
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router'
import constants from "../services/constants"
import styles from '../styles/RequestAccessPage.module.scss'

export default function RequestAccesspage() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const handleHome = (e: any) => {
        navigate('/');
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={5}>
                <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
                    <Box>
                        <HsbShareLogo width={222} />
                    </Box>
                    <Box>
                        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <img alt="private project logo" src={icon} width={500} />
                            <Typography
                                variant="h4"
                                {...constants.pageHeading}
                                style={{ paddingBottom: 10, textAlign: "center" }}
                            >
                                {t('RequestAccess.title')}
                            </Typography>
                            <Typography
                                {...constants.pageText}
                                style={{ paddingBottom: 15, textAlign: "center" }}
                            >
                                {t('RequestAccess.shortDescription')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={styles.section}>
                                <Typography
                                    {...constants.pageText}
                                    style={{ paddingBottom: 15, textAlign: "center" }}
                                >
                                    {t('RequestAccess.contactTeamAdmin')}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid style={{ paddingTop: 10 }} item xs={12}>
                            <div className={`${styles['button-secondary']} ${styles.section}`}>
                                <RoundedButton
                                    type="submit"
                                    variant="contained"
                                    onClick={handleHome}
                                >
                                    {t('RequestAccess.home')}
                                </RoundedButton>
                            </div>
                        </Grid>
                    </Box>
                    <Box>
                        <HalfFooter />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
                <div className={styles.clipi} style={{ position: "relative", height: "100vh" }}></div>
            </Grid>
        </Grid>
    )
}
