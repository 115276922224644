import Cookies from 'universal-cookie'
import constants from './constants'
import renewAccessToken from './renewAccessToken'

const axios = require('axios').default
let requestInterceptor: any
let responseInterceptor: any

const setInterceptors: () => void = async () => {
    axios.interceptors.request.eject(requestInterceptor)
    axios.interceptors.request.eject(responseInterceptor)

    requestInterceptor = axios.interceptors.request.use(
        (request: any) => {
            const cookies = new Cookies()
            let accessToken = cookies.get(constants.authCookie)?.token

            if (accessToken) {
                request.headers['x-access-token'] = accessToken
            } else {
                delete request.headers['x-access-token']
            }
            return request
        },
        (error: any) => {
            return Promise.reject(error)
        }
    )
    responseInterceptor = axios.interceptors.response.use(
        (response: any) => {
            return response
        },
        async (error: any) => {
            const originalRequest = error.config
            if (
                error?.response?.data?.status === 401 &&
                (error?.response?.data?.name === 'Token Expired' || error?.response?.data?.name === 'Invalid Token') &&
                (error?.response?.data?.message === 'The access token has expired, please renew it.' ||
                    error?.response?.data?.message === 'A valid access token is required. No token found.') &&
                !originalRequest._retry
            ) {
                const cookies = new Cookies()
                let session = cookies.get(constants.authCookie)
                if (!session?.refreshToken) {
                    return Promise.reject(error)
                }
                originalRequest._retry = true
                const data = await renewAccessToken(session?.refreshToken)
                if (data?.user?.token && data?.user?.exp) {
                    session.token = data?.user?.token
                    session.exp = data?.user?.exp
                    cookies.set(constants.authCookie, session, { path: '/', sameSite: 'lax', expires: new Date(session.exp) })
                }
                axios.defaults.headers['x-access-token'] = session.token
                return axios(originalRequest)
            }
            if (error?.response?.data?.status === 502 || error.code?.toLowerCase() === "err_network") {
                return Promise.reject({ ...error, response: { status: 502, message: "The server could not be reached!" }, });
            }
            return Promise.reject(error)
        }
    )
}

export default setInterceptors
