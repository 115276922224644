import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles/UserSearch.module.css'
import restAPI from '../../services/rest-api'

const mapEmailUserId = {}

const UserSearch: React.FC<{ projectId: string, text: string, className?: string, minWidth?: number, minHeight?: number, onCommit: (value: string) => void, onBlur: (event) => void }> = ({ projectId, text, className = '', minWidth = 150, minHeight = 150, onCommit, onBlur }) => {
    const { t } = useTranslation('common')
    const assignToText = t('IssueList.AssignedTo')
    const email = text.replace(assignToText, '')
    const [, setAssignToEmailValue] = useState<string>(email)
    const [projectMembers, setProjectMembers] = useState([])
    const [updateFocus, setUpdateFocus] = useState(false);
    const [autocompleteOpen,] = useState(true);
    const emailRef = useRef(null);

    const onChange = (event: React.SyntheticEvent,
        value: string,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<string>): void => {
        if (!value) {
            return;
        }
        setAssignToEmailValue(value)
        onCommit(mapEmailUserId[value])//we need the user._id not email here.
    }

    const onFocus = (event) => {
        if (updateFocus) {
            event.target.select()
            setUpdateFocus(false)
        }
    }

    useEffect(() => {
        if (updateFocus && emailRef.current) {
            emailRef.current.focus();
        }
    }, [updateFocus]);

    useEffect(() => {
        if (!updateFocus && emailRef.current) {
            setUpdateFocus(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (projectId) {
            restAPI.getProjectUsers(projectId).then(p => {
                setProjectMembers(p?.data?.map(projectMember => {
                    mapEmailUserId[projectMember?.email] = { _id: projectMember?._id, email: projectMember?.email }
                    return projectMember?.email
                }));
            });
        }
    }, [projectId]);

    return <div>
        <div className={styles.text + ' ' + className}>
            <Autocomplete options={projectMembers}
                open={autocompleteOpen}
                autoHighlight={true}
                onBlur={onBlur}
                autoSelect
                style={{ height: '43px', margin: '0px' }}
                onChange={onChange}
                renderInput={(params) => <TextField placeholder={t('UserSearch.TypeToSearch')} ref={emailRef} autoFocus onFocus={onFocus}  {...params} variant="standard" />}
            />
        </div>
    </div>
}

export default UserSearch;