import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCircle, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Skeleton } from "@mui/material";
import styles from "./ImageOrPlaceholder.module.scss";

export default function ImageOrPlaceholder(props: ImageOrPlaceholderProps) {
    const { imageURL, size, placeholderIcon, loading } = props;

    return (
        loading ?
            <Skeleton className={`${styles['image-container']} ${size ? styles[size] : ''}`}>
                <FontAwesomeIcon icon={faCircle} />
            </Skeleton>
            : <Box className={`${styles['image-container']} ${size ? styles[size] : ''} ${!imageURL ? styles['bordered'] : ''}`} sx={imageURL && { backgroundImage: `url(${imageURL})` }}>
                {
                    !imageURL && <FontAwesomeIcon icon={placeholderIcon || faImage} />
                }
            </Box>
    );
}

export interface ImageOrPlaceholderProps {
    imageURL: string;
    size?: "extra-small" | "small" | "default" | "large";
    placeholderIcon?: IconDefinition;
    loading?: boolean;
}
