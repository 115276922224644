import config from './config';

const axios = require('axios').default;

const renewAccessToken = async (refreshToken: string) => {
        try {
                const response = await axios.get(`${config.server}/refresh/accessToken?refresh_token=${refreshToken}`);
                return response?.data;
        } catch (error) {
                return Promise.reject(error);
        }
}

export default renewAccessToken;